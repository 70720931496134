import React  from "react";
import {IHouseFilter} from "../../../Interfaces/IHouseFilter";
import "./PriceFilter.scss";
import Input from "../../Input/Input";
import { getReadablePrice } from "../../../Helpers/numbers";
import { SliderInput } from "../../SliderInput/SliderInput";

interface IProps {
    value: IHouseFilter,
    onChange: any
}
interface IState {
    dragFrom: number,
    dragTo: number,
}

export class PriceFilter extends React.Component<IProps, IState>{
    public min = 50000;
    public max = 2500000;
    public timeout: any;

    constructor(props: IProps) {
        super(props);

        // @ts-ignore
        const from = props.value.value && props.value.value[0] ? props.value.value[0] : 0;
        // @ts-ignore
        const to = props.value.value && props.value.value[1] ? props.value.value[1] : this.max;
        this.state = {
            dragFrom: from,
            dragTo: to,
        };
    }

    get fromValue() {
        if (this.state.dragFrom === null || this.state.dragFrom <= 50000) {
            return 'Onbeperkt';
        }
        return "€ " + getReadablePrice(this.state.dragFrom);
    }
    get toValue() {
        if (this.state.dragTo === null || this.state.dragTo >= 2500000) {
            return 'Onbeperkt';
        }
        return "€ " + getReadablePrice(this.state.dragTo);
    }


    onChange(value: any) {
        this.setState({dragFrom: value[0], dragTo: value[1]});
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this.props.onChange(this.props.value.key, value), 500);
    }

    render() {
        return (
            <React.Fragment>
                <SliderInput step={10000} min={this.min} max={this.max} onChange={(value: any) => { this.onChange(value); }} range={true} value={[this.state.dragFrom, this.state.dragTo]} />
                <Input>
                    <input type="text" placeholder="Minimaal" name="from" readOnly value={this.fromValue}/>
                    <input type="text" placeholder="Maximaal" name="to" readOnly value={this.toValue}/>
                </Input>
            </React.Fragment>
        );
    }
}