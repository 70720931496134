import React, { useState } from "react";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import { PageImage } from "../../Components/PageImage/PageImage";
import placeholder from "../../resources/image-placeholder.svg";
import { PageContent } from "../../Components/PageContent/PageContent";
import { NavLink } from "react-router-dom";
import { Posts } from "../../Components/Posts/Posts";

interface IProps {

}

export function PostArchive(props: IProps) {
    const [headImage, setHeadImage] = useState<string|undefined>();


    const breadcrumbs = (() => {
        const crumbs = [];

        crumbs.push(
            <li>
                <NavLink to="/informatie">Informatie</NavLink>
            </li>);

        return crumbs;
    })();

    return (
        <div className="post-archive-wrapper">
            <ScrollToTopOnMount/>
            <PageImage small={true} src={headImage || placeholder} />

            <PageContent className="regular-padding" crumbs={breadcrumbs}>
                <h1>Informatie</h1>
                <p>Hier vindt u een overzicht van alle berichten die wij in de afgelopen tijd op onze site hebben geplaatst. Op de manier proberen wij u altijd van de meest actuele informatie te kunnen voorzien over het kopen van een vakantiehuis in Spanje.</p>

                <div className="inline-posts-wrapper">
                    <Posts setHeadImage={setHeadImage}/>
                </div>
            </PageContent>
            {/*<PostBar title="Wellicht interessant." query={this.postsQuery}/>*/}
            {/*<HouseBar title="Bekijk ook deze woningen." query={this.housesQuery} delayed={true}/>*/}
        </div>
    )
}