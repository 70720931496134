import React from 'react';
import PictureSlider from "../../Components/PictureSlider/PictureSlider";
import FrontSearch from "../../Components/FrontSearch/FrontSearch";
import './Frontpage.scss'
import HouseBar from "../../Containers/HouseBar/HouseBar";
import { HouseQuery } from "../../Interfaces/HouseQuery";
import {PostQuery} from "../../Interfaces/PostQuery";
import PostBar from "../../Containers/PostBar/PostBar";
import LocationBar from "../../Containers/LocationBar/LocationBar";
import { CityQuery } from "../../Interfaces/CityQuery";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";

class Frontpage extends React.Component {
    get housesQuery() {
        return {
            meta: [{
                    key: 'zw_on_frontpage',
                    value: 1
            }],
            per_page: 8,
        } as HouseQuery
    }

    get postsQuery() {
        return {
            category_name: 'Informatie',
            per_page: 6,
        } as PostQuery
    }

    get cityQuery() {
        return {
            orderby: 'rand',
            order: 'ASC',
            per_page: 10
        } as CityQuery
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="front-search-and-picture-wrapper">
                    <FrontSearch/>
                    <PictureSlider/>
                </div>
                <PostBar title="Van zoeken tot wonen." query={this.postsQuery}/>
                <HouseBar title="Uitgelichte woningen." query={this.housesQuery} delayed={true} desiredLength={8}/>
                <LocationBar title="Wat is uw favoriete woonplaats?" query={this.cityQuery}/>
            </div>
        );
    }
}

export default Frontpage;