import React from "react";
import "./Icon.scss";

interface IProps {
    type: string
    onClick?: () => void
}

export function Icon(props: IProps) {

    return (
        <span className={"icon-" + props.type} onClick={props.onClick} />
    );
}