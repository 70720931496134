import React, {ReactNode} from 'react';
import './Topmenu.scss';
import { NavLink } from "react-router-dom";

interface IProps {
    children?: ReactNode|ReactNode[]
}

export function Topmenu(props: IProps) {
    return (
        <div className="top-menu">
            <ul>
                <li>
                    <NavLink exact activeClassName="active" to="/">Home</NavLink>
                </li>
                <li>
                    <NavLink  activeClassName="active" to="/aanbod">Aanbod</NavLink>
                </li>
                {props.children}
                <li>
                    <NavLink  activeClassName="active" to="/over-ons">Over ons</NavLink>
                </li>
            </ul>
        </div>
    );
}