import React from "react";
import TagBar from "../../../Containers/TagBar/TagBar";
import Tag from "../../Tag/Tag";
import {IHouseType} from "../../../Interfaces/IHouseType";
import {IHouseFilter} from "../../../Interfaces/IHouseFilter";
import {filterTypeOptions} from "../../../Core/Filters/Filters";

interface IProps {
    value: IHouseFilter,
    onChange: any
}
interface IState {
}

export class TypeFilter extends React.Component<IProps, IState>{
    get types() {
        return filterTypeOptions.map((type: IHouseType) => {
            return <Tag key={type.id} primary={true} hollow={!this.inFilter(type.id)} onClick={() => this.handleClick(type.id)}>{type.name}</Tag>
        });
    }

    get value(): number[] {
        if (!this.props.value.value) {
            return [];
        }
        return this.props.value.value as number[];
    }

    inFilter(id: number) {
        return (this.value as number[]).includes(id);
    }

    handleClick(id: number) {
        let newValue = this.value;
        if (this.inFilter(id)) {
            newValue = newValue.filter((value: number) => {
                return value !== id;
            });
        } else {
            newValue.push(id);
        }
        this.props.onChange(this.props.value.key, newValue);
    }

    render() {
        return (
            <React.Fragment>
                <TagBar full={true}>
                    {this.types}
                </TagBar>
            </React.Fragment>
        );
    }
}