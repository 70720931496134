import React from "react";
import Input from "../Input/Input";
import { Datepicker } from "../Datepicker/Datepicker";
import { Choice } from "../Choice/Choice";
import { MonthSelect } from "../MonthSelect/MonthSelect";

interface IProps {
    children?: any,
    onSubmit: any
}
interface IState {
    formData: any
}

export class Form extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            formData: {}
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    get children() {
        let children = this.props.children[0] ? this.props.children : [this.props.children];

        children = children.map((child: any, index: number) => {
            if (typeof child !== 'object') {
                return child;
            }
            const props = { key: index} as any;

            if (child && child.type && (typeof child.type === 'object' || typeof child.type === 'function')) {
                if (child.type === Input || child.type === Datepicker || child.type === Choice || child.type === MonthSelect) {
                    props.onChange = this.handleInput;
                }
            }

            return React.cloneElement(child, props);
        });

        return children;
    }

    componentDidMount(): void {
        this.initData(this.props.children);
    }

    initData(children: any) {
        const formData = this.state.formData;
        children.forEach((child: any, index: number) => {
            if (child.type === 'input' && child.props.value && !this.state.formData[child.props.name]) {
                formData[child.props.name] = child.props.value;
            } else if ((typeof child.type === 'object' || typeof child.type === 'function') && (child.type === Input)) {
                const input = child.props.children[0] ? child.props.children[0] : child.props.children;
                if (input && input.name && input.value) {
                    formData[input.name] = input.value;
                } else if (input.props.name && input.props.value) {
                    formData[input.props.name] = input.props.value;
                }
            } else if (child.type === MonthSelect && child.props.name && child.props.month) {
                formData[child.props.name] = child.props.month;
            }
        });
        this.setState({formData});
    }

    handleInput(event: any) {
        const formData: any = this.state.formData;
        formData[event.target.name] = event.target.value;

        this.setState({formData});
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const data = this.state.formData;
        this.props.onSubmit(data);
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.children}
            </form>
        );
    }
}