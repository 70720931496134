import Wordpress from "squde-wp-api";
import {IHouse} from "../Interfaces/IHouse";
import {IProvince} from "../Interfaces/IProvince";
import {ICity} from "../Interfaces/ICity";
import {IFrontImage} from "../Interfaces/IFrontImage";
import {ucFirstEachWord} from "../Helpers/strings";

interface ILocationStorage {
    date: object,
    data: IProvince[]
}

let locationFetch: any;


export interface ISlugResult {
    ID: string
    type: string
}

class CustomWordpress extends Wordpress {
    get houses() {
        const api = this;
        return {
            get: (params: { [s: string]: string|boolean; } = {}, signal: any = null, returnResponse: boolean = false, priority?: number): any => {
                return api.get('woning/?' + api.buildQuery(params), signal, undefined, returnResponse, priority)
                    .then((result: any) => result);
            }
        }
    }

    get house() {
        const api = this;
        return {
            get: (params: any, signal: any = null, priority?: number): any => {
                return api.houses.get(params, signal, undefined, priority).then((houses: IHouse[]) => {
                    if (houses[0]) {
                        return houses[0];
                    }
                    return null;
                });
            },
            favourite: (houseId: number, isFavourite: boolean, signal: any = null): any => {
                return api.post('favourite', {houseId, isFavourite: (isFavourite ? 1 : 0)}, signal);
            }
        }
    }

    get locations() {
        const api = this;
        return {
            get: (signal: any = null): Promise<IProvince[]> => {
                if (locationFetch) {
                    return locationFetch;
                }
                let storedLocations = window.localStorage.getItem('locations');
                if (storedLocations) {
                    storedLocations = JSON.parse(storedLocations);
                    // @ts-ignore
                    if (storedLocations && storedLocations.data.length > 0) {
                        // @ts-ignore
                        storedLocations = (storedLocations as ILocationStorage);
                        // @ts-ignore
                        const timestamp = new Date(storedLocations.date);
                        const currentDate = new Date();
                        const diff = (currentDate.getTime() - timestamp.getTime()) / 1000;

                        if (diff > 3600) {
                            window.localStorage.removeItem('locations');
                        } else {
                            // @ts-ignore
                            return Promise.resolve(storedLocations.data);
                        }
                    }
                }

                locationFetch = api.get('locations', signal)
                    .then((result: any) => result)
                    .then((locations: IProvince[]) => {
                        return locations.map((location: IProvince) => {
                            location.name = ucFirstEachWord(location.name);
                            return location;
                        });
                    })
                    .then((locations: IProvince[]) => {
                        window.localStorage.setItem('locations', JSON.stringify({date: new Date(), data: locations} as any));
                        return locations;
                    });
                return locationFetch;
            }
        }
    }

    get cities() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null): Promise<ICity[]> => {
                return api.locations.get(signal).then((provinces: IProvince[]) => {
                    const cities: ICity[] = [];
                    provinces.forEach((province: IProvince) => {
                        province.cities.forEach((city: ICity) => {
                            cities.push(city);
                        });
                    });
                    return cities;
                });
            }
        }
    }

    get frontImages() {
        const api = this;
        return {
            get: (signal: any = null): Promise<IFrontImage[]> => {
                return api.get('frontImages', signal);
            }
        }
    }

    get houseCount() {
        const api = this;
        return {
            get: (signal: any = null): Promise<number> => {
                return api.get('houseCount', signal);
            }
        }
    }

    get slug() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority?: number): Promise<ISlugResult> => {
                return api.get('slug/?' + api.buildQuery(params), signal, undefined, undefined, priority)
                    .then((result: any) => result);
            }
        }
    }

    get auth2() {
        const api = this;
        return {
            forgot: (email: string, signal: any = null): Promise<any> => {
                return api.post('user/forgotPassword', {user_login: email}, signal)
                    .then((response: any) => {
                        if ('done' in response) {
                            return response;
                        }
                        throw response;
                    });
            },
            resetPassword: (params: { [s: string]: string; } = {}, signal: any = null): Promise<any> => {
                return api.post('user/resetPassword', params, signal);
            }
        };
    }
}
export function wp() {
    return new CustomWordpress("https://uwcasa.nl/wp-json/wp/v2/");
}