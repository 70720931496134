import React from "react";

interface IProps {
}

export function Register(props: IProps) {
    return (
        <div className="register-wrapper">
            <h1>Registreren.</h1>

        </div>
    );
}