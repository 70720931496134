import React, { useEffect, useMemo, useState } from 'react';
import { HouseFilters } from "../../Components/HouseFilters/HouseFilters";
import HouseWidget from "../../Components/HouseWidget/HouseWidget";
import { IHouse } from "../../Interfaces/IHouse";
import { HouseQuery } from "../../Interfaces/HouseQuery";
import './Houses.scss';
import { Filters } from "../../Core/Filters/Filters";
import { LoadingIndicator } from "../../Components/LoadingIndicator/LoadingIndicator";
import InfiniteScroll from "react-infinite-scroll-component";
import { wp } from "../../Factories/Wordpress";
import { HouseSortings } from "../../Components/HouseSortings/HouseSortings";
import { ScrollUpButton } from "../../Components/ScrollUpButton/ScrollUpButton";
import { Pagination } from "../../Components/Pagination/Pagination";
import { MetaQuery } from "../../Interfaces/MetaQuery";
import { useHistory } from "react-router-dom";

interface IProps {
    inline?: boolean
    city?: number
    province?: number
}

interface IState {
    isFetching: boolean
    page: number
    houses?: IHouse[]
    lastSet?: IHouse[]
    filters?: any
    sort?: string | null
    noResults?: boolean
    headers?: { [key: string]: string; }
}

let abortController = new AbortController();

export function Houses(props: IProps) {
    const getUrlQuery = useMemo(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
            return JSON.parse(atob(hash));
        }
        return {};
    }, []);

    const urlQuery = getUrlQuery();

    const [houses, setHouses] = useState<IHouse[]>([]);
    const [lastSet, setLastSet] = useState<IHouse[]>([]);
    const [filters, setFilters] = useState<any>(urlQuery.meta || Filters.forQuery());
    const [page, setPage] = useState<number>(urlQuery.page || 1);
    const [sort, setSort] = useState<string | null>(!window.localStorage.getItem('sortBy') ? 'date_new' : window.localStorage.getItem('sortBy'));
    const [headers, setHeaders] = useState<{ [key: string]: string; }>({});
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [noResults, setNoResults] = useState<boolean>(false);
    const [fetchedPages, setFetchedPages] = useState<number[]>([]);
    const history = useHistory();

    const housesPerPage = props.inline ? 10 : 40;

    const query = ((): HouseQuery => {
        const query = {
            per_page: housesPerPage,
            page: page,
            meta: filters,
            sort: sort
        } as HouseQuery;

        if (props.city || props.province) {
            query.meta = (query.meta ? query.meta : []).filter((meta: MetaQuery, index: number) => {
                return meta.key !== 'woning_location';
            });
            const meta = {
                key: 'woning_location',
                value: [!!props.city ? props.city : props.province],
                compare: 'IN'
            } as any as MetaQuery;
            query.meta.push(meta);

        }

        return query;
    })();
    const pagination = (() => {
        if (isFetching && page === 1) {
            return '';
        }
        let length = 1;
        if (headers && headers['x-wp-totalpages']) {
            length = parseInt(headers['x-wp-totalpages']);
        }
        return <Pagination length={length} active={page - 1} onPageChange={(selectedItem: { selected: number }) => {
            setPage(selectedItem.selected + 1);
            return selectedItem;
        }}/>
    })();
    const classes = (() => {
        const classes = ['houses-wrapper'];
        if (props.inline) {
            classes.push('inline-houses');
        }
        return classes.join(' ');
    })();
    const hasMoreHouses = (() => {
        if (!houses) {
            return true;
        }
        if (houses.length === 0 || !lastSet) {
            return false;
        }
        return lastSet.length === housesPerPage;
    })();
    const handleFilters = () => {
        abortController.abort();
        setFilters(Filters.forQuery());
        setFetchedPages([]);
        setPage(1);
        setHouses([]);
    };
    const handleSortings = (event: any) => {
        abortController.abort();
        window.localStorage.setItem('sortBy', event.target.value);
        setSort(event.target.value);
        setFetchedPages([]);
        setPage(1);
        setHouses([]);
    };
    // const getNextPage = () => {
    //     if (!isFetching) {
    //         setPage((prevState: number) => prevState + 1);
    //     }
    // };

    const houseElements = (() => {
        if (!houses) {
            return (new Array(4).fill(0)).map((value, index) => {
                return <HouseWidget key={index}/>
            });
        }
        return houses.map((house: IHouse) => {
            return <HouseWidget key={house.id} house={house}/>;
        })
    })();

    const retrieve = (merge = false) => {
        // console.log('begin');
        // cancelRequest();
        setIsFetching(true);
        setNoResults(false);

        setFetchedPages((prevState: number[]) => {
            prevState.push(page);
            return prevState;
        });

        abortController = new AbortController();

        wp().houses.get(query as {}, abortController.signal, true).then((response: Response) => {
            const newHeaders: { [key: string]: string; } = {};
            response.headers.forEach((value: string, name: string) => {
                newHeaders[name] = value;
            });


            response.json().then((answer: IHouse[]) => {
                answer = wp().fixData(answer);
                const originalAnswer = answer;
                if (merge && houses) {
                    answer = answer.filter((newHouse: IHouse) => houses && houses.filter((house: IHouse) => house.id === newHouse.id).length === 0);
                    answer = houses.concat(answer);
                }
                setHeaders(newHeaders);
                setLastSet(originalAnswer);
                setHouses(answer);
                setIsFetching(false);
                setNoResults(answer.length === 0);

                // const newHistoryState = window.history.state;
                // newHistoryState.houses = this.state;
                // window.history.replaceState(newHistoryState, 'state');
            })
        }).catch((e: any) => {
            setIsFetching(false);
        });
    };

    // useEffect(() => {
    //     if (!isFetching && page === 1 && houses.length === 0 && fetchedPages.indexOf(page) < 0) {
    //         retrieve();
    //     }
    // }, [houses, isFetching, page, fetchedPages]);
    //
    // useEffect(() => {
    //     if (!isFetching && page > 1 && fetchedPages.indexOf(page) < 0) {
    //         retrieve();
    //     }
    // }, [page, isFetching]);
    useMemo(() => {
        const query = getUrlQuery();
        setPage(query.page || 1);
        setFilters(query.meta || Filters.forQuery());

        setTimeout(() => {
            abortController.abort();
            retrieve();
        });

    }, [window.location.hash]);

    useEffect(() => {
        const currentHash = window.location.hash.substr(1);
        const q = btoa(JSON.stringify(query));
        if (q !== currentHash) {
            window.location.hash = '#' + q;
        }
    }, [query]);


    return (
        <React.Fragment>
            <HouseFilters inline={props.inline} onChange={handleFilters}/>
            <HouseSortings inline={props.inline} onChange={handleSortings} value={sort}/>

            {pagination}
            <div className={classes}>
                {isFetching ? <LoadingIndicator contained={true} inline={props.inline}/> : ''}
                {
                    houses && houses.length > 0 ?
                        (
                            <>
                                <div className={"paginated-houses-wrapper " + (!props.inline ? 'full' : '')}>
                                    {houseElements}
                                </div>
                                {pagination}
                            </>
                        ) :
                        (
                            noResults ?
                                (
                                    <div className="no-houses-found">
                                        <h1>Helaas.</h1>
                                        <p>Er zijn geen woningen gevonden die voldoen aan de bovenstaande criteria.
                                            <br/>
                                            Pas uw filters aan om het bereik te vergroten.</p>
                                    </div>
                                ) : (props.inline ? '' : <LoadingIndicator contained={true}/>)
                        )
                }
            </div>
        </React.Fragment>
    );
}
