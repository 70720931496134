import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface IProps {
    href: string,
    children?: any
}

export function ContentLink(props: IProps) {
    const { href, children } = props;

    const isInternal = (() => {
        return ['://uwcasa.nl', '://www.uwcasa.nl', '://uwcasa.be', '://www.uwcasa.be'].filter((string: string) => {
            return href.split(string).length > 1;
        }).length > 0;
    })();

    if (isInternal) {
        let path = '/' + (() => {
            return href.split('/').filter((str: string, index: number) => {
                return index > 2;
            }).join('/');
        })();

        path = (() => {
            path = path.split('/uncategorized/').join('/');
            path = path.split('/artikelen/informatie').join('/informatie');
            return path;
        })();

        return <Link to={path}>{children}</Link>;
    }

    return (
        <a href={href} target="_blank" rel="nofollow noopener noreferrer">
            {children}
        </a>
    )
}