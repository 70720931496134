import React, { useEffect, useState } from "react";
import "./Posts.scss";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { Pagination } from "../Pagination/Pagination";
import { wp } from "../../Factories/Wordpress";
import PostWidget from "../PostWidget/PostWidget";
import { IPost } from "../../Interfaces/IPost";
import { PostQuery } from "../../Interfaces/PostQuery";

interface IProps {
    setHeadImage?: (img: string) => void
}

export function Posts(props: IProps) {
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [posts, setPosts] = useState<IPost[]>();
    const [headers, setHeaders] = useState<{ [key: string]: string; }>();
    const [headImage, setHeadImage] = useState<string|undefined>();

    const pagination = (() => {
        let length = 1;
        if (headers && headers['x-wp-totalpages']) {
            length = parseInt(headers['x-wp-totalpages']);
        }
        return <Pagination length={length} active={page - 1} onPageChange={(selectedItem: { selected: number }) => {

            setPage(selectedItem.selected + 1);

            return selectedItem;
        }} />
    })();

    useEffect(() => {

        const query: PostQuery = {
            per_page: 10,
            page: page,
            category_name: 'Informatie',
        };

        (wp().posts.get(query as any, undefined, true) as Promise<any>).then((response: Response) => {
            const headers: { [key: string]: string; } = {};
            response.headers.forEach((value: string, name: string) => {
                headers[name] = value;
            });

            response.json().then((answer: IPost[]) => {
                answer = wp().fixData(answer);
                setHeaders(headers);
                setPosts(answer);
                setIsFetching(false);
            })
        });
    }, [page]);

    useEffect(() => {
        if (posts && !headImage) {
            setHeadImage(posts[0].thumbnail);
        }
    }, [posts, headImage]);

    useEffect(() => {
        if (headImage && props.setHeadImage) {
            props.setHeadImage(headImage);
        }
    }, [props, headImage]);

    return (
        <div className="posts-wrapper">
            {isFetching || !posts ? <LoadingIndicator inline={true} /> : (
                <>
                    {pagination}
                    <div className="paginated-posts-wrapper">
                        {posts.map((post: IPost, index: number) => {
                            return <PostWidget key={index} post={post} />;
                        })}
                    </div>
                    {pagination}
                </>
            ) }
        </div>
    )
}