import React, { RefObject } from "react";
import "./FooterLocationMenu.scss";
import {IProvince} from "../../Interfaces/IProvince";
import {ICity} from "../../Interfaces/ICity";
import {wp} from "../../Factories/Wordpress";
import { NavLink } from "react-router-dom";
import { first, flatten, sum } from 'lodash';

interface IProps {

}

interface IState {
    menu?: any;
    provinces?: IProvince[];
}

class FooterLocationMenu extends React.Component<IProps, IState>{

    public ref: RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.state = { };

        this.ref = React.createRef();
    }

    componentDidMount(): void {
        wp().locations.get().then((provinces: IProvince[]) => {
            this.setState({provinces, menu: provinces.map((province: IProvince, index: number) => {
                    const provincePath = '/aanbod/' + province.slug;
                    const cities = province.cities;
                    const submenu = cities.map((city: ICity) => {
                        const url = '/aanbod/' + city.province.slug + '/' + city.slug;
                        return <li key={city.term_id}>
                            <NavLink to={url}>{city.name}</NavLink>
                        </li>
                    });
                    return (
                        <div className="submenu" key={province.term_id}>
                            <NavLink to={provincePath}>{province.name}</NavLink>
                            <ul>
                                {submenu}
                            </ul>
                        </div>
                    );
                })}, () => {
                this.setHeight();
            });
        });

        window.addEventListener('resize', this.setHeight);
    }

    get menu() {
        return this.state.menu;
    }

    get locationCount() {
        return flatten((this.state.provinces || []).map(item => item.cities)).length;
    }

    setHeight() {
        if (!this.ref) {
            return;
        }
        const div = this.ref.current as HTMLDivElement;

        const children = ((Array.from(div.children)) as HTMLDivElement[]).map((child: HTMLDivElement) => {
            return child.clientHeight + 16;
        }).sort().reverse();

        const totalHeight = sum(children);

        // div.style.height = (first(children) || 800).toString() + 'px';

        if(window.screen.width >= 768) {
            div.style.height = (totalHeight / 3) + 'px';
        } else {
            div.style.height = (totalHeight / 2) + 'px';
        }

    }

    render() {
        return (
            <div className="footer-location-menu-wrapper">
                <h1>Vind uw droomhuis in {this.locationCount} locaties!</h1>
                <div className="footer-location-menu" ref={this.ref}>
                    {this.menu}
                </div>
            </div>
        );
    }
}

export default FooterLocationMenu;
