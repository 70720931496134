import React from 'react';
import './FrontSearch.scss';
import Input from "../Input/Input";
import {IHouseFilter} from "../../Interfaces/IHouseFilter";
import {Filters, filterTypeOptions} from "../../Core/Filters/Filters";
import {SearchSelect} from "../SearchSelect/SearchSelect";
import {IHouseType} from "../../Interfaces/IHouseType";
import {wp} from "../../Factories/Wordpress";
import {ICity} from "../../Interfaces/ICity";
import {withRouter} from "react-router";
import { getReadablePrice } from "../../Helpers/numbers";

interface FormData {
    type?: string,
    price?: any,
    city?: string,
}
interface IProps {
    history?: any
}
interface IState {
    formData: FormData;
    houseCount?: number;
    priceMin?: number;
    priceMax?: number;
}

class FrontSearch extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formData: {
                price: {}
            }
        };
        this.handleInput = this.handleInput.bind(this);
        this.handlePriceInput = this.handlePriceInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fixPrice = this.fixPrice.bind(this);
    }

    componentDidMount(): void {
        wp().houseCount.get().then((count: number) => {
            this.setState({houseCount: count});
        });
    }

    handleInput(event: any) {
        const formData: any = this.state.formData;
        formData[event.target.name] = event.target.value;

        // console.log({formData});

        this.setState({formData});
    }

    fixPrice() {
        if (this.state.formData && this.state.formData.price) {
            const newPrice: {min?: number, max?: number} = {};
            if (this.state.formData.price.min && this.state.formData.price.min < 50000) {
                newPrice.min = 50000;
            }
            if (this.state.formData.price.min && this.state.formData.price.max <= this.state.formData.price.min) {
                newPrice.max = parseInt(this.state.formData.price.min) + 10000;
            } else if (this.state.formData.price.max && this.state.formData.price.max < 50000) {
                newPrice.max = 60000;
            }
            if (Object.keys(newPrice).length > 0) {
                const formData: any = this.state.formData;
                formData.price = newPrice;
                const newState: IState = {formData};
                if (newPrice.min) {
                    newState.priceMin = newPrice.min;
                }
                if (newPrice.max) {
                    newState.priceMax = newPrice.max;
                }
                this.setState(newState)
            }
        }
    }
    handlePriceInput(event: any) {

        const formData: any = this.state.formData;
        const priceData = formData.price;
        priceData[event.target.name.split('min').length > 1 ? 'min' :  'max'] = event.target.value;
        formData.price = priceData;


        const newState: IState = {
            formData
        };
        if (event.target.name.split('min').length > 1) {
            newState.priceMin = event.target.value;
        } else {
            newState.priceMax = event.target.value;
        }
        this.setState(newState);
    }

    async handleSubmit(event: any) {
        event.preventDefault();
        const data = this.state.formData;

        if (data.type) {
            Filters.set('type', [parseInt(data.type)]);
        }
        if (data.price && data.price.min && data.price.max) {
            Filters.set('price', [parseInt(data.price.min), parseInt(data.price.max)]);
        }
        if (data.city) {
            const city = await this.findCity(parseInt(data.city));
            Filters.set('cities', [city]);
        }

        this.props.history.push('/aanbod');
    }

    getFilter(key: string): IHouseFilter {
        let value;
        const filters = Filters.all();
        if (filters && filters[key] !== undefined) {
            value = filters[key];
        }
        return {key: key, value: value};
    }

    searchTypes(search: string): Promise<any> {
        return Promise.resolve(filterTypeOptions.filter((type: IHouseType) => {
            return type.name.toLowerCase().split(search.toLowerCase()).length > 1;
        }));
    }

    cities(search: string): Promise<any> {
        return wp().cities.get().then((cities: ICity[]) => {
            return cities.filter((city: ICity) => {
                return city.name.toLowerCase().split(search.toLowerCase()).length > 1;
            }).map((city: ICity) => {
                return {id: city.term_id, name: city.name} as any;
            })
        })
    }

    async findCity(id: number) {
        const cities = await wp().cities.get().then((cities: ICity[]) => {
            return cities.filter((city: ICity) => {
                return city.term_id === id;
            }).map((city: ICity) => {
                return {id: city.term_id, name: city.name} as any;
            });
        });
        if (cities[0]) {
            return cities[0];
        }
        return null;
    }

    render() {
        return (
            <form className="front-search" onSubmit={this.handleSubmit}>
                <h1 className="show-for-medium">Vind uw eigen Casa in nog geen 5 minuten.</h1>
                <h1 className="show-for-small-only">Uw eigen Casa in nog geen 5 minuten.</h1>
                {this.state.houseCount ? <h4>Zoek in {getReadablePrice(this.state.houseCount)} woningen...</h4> : <h4>&nbsp;</h4>}
                <Input label="Soort">
                    <SearchSelect placeholder={"Alle soorten"} name={"type"} onChange={this.handleInput} search={this.searchTypes}/>
                </Input>
                <Input label="Prijsklasse">
                    <input
                        type="number"
                        value={this.state.priceMin || ''}
                        placeholder="Minimaal"
                        step={10000}
                        min={50000}
                        name="price[min]"
                        onChange={this.handlePriceInput}
                        onBlur={this.fixPrice}/>
                    <input
                        type="number"
                        value={this.state.priceMax || ''}
                        placeholder="Maximaal"
                        step={10000}
                        min={(this.state.formData && this.state.formData.price && this.state.formData.price.min) ? (parseInt(this.state.formData.price.min) + 10000) : 60000}
                        name="price[max]"
                        onChange={this.handlePriceInput}
                        onBlur={this.fixPrice}/>
                </Input>
                <Input label="Woonplaats">
                    <SearchSelect placeholder={"Overal"} name={"city"} onChange={this.handleInput} search={this.cities}/>
                </Input>
                <div className="text-right">
                    <button className="button secondary" type="submit">Zoeken</button>
                </div>
            </form>
        );
    }
}

// @ts-ignore
export default withRouter(FrontSearch);
