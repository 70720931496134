import React from "react";
import {NavLink} from "react-router-dom";
import './Breadcrumbs.scss';


interface IProps {
    crumbs?: any
}
interface IState {

}

export class Breadcrumbs extends React.Component<IProps, IState> {
    get crumbs(): any {
        if (!this.props.crumbs) {
            return [];
        }
        return this.props.crumbs
            .map((crumb: any) => { return [<li className="between" />, crumb]; })
            .reduce((a: any, b: any) => { return a.concat(b); })
            .map((li: any, index: number) => {
                return <React.Fragment key={index}>{li}</React.Fragment>
            });
    }
    render() {
        return (
            <div className="breadcrumbs">
                <ul>
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    {this.crumbs}
                </ul>
            </div>
        );
    }
}