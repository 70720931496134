import './PostBar.scss';
import React from 'react';
import {PostQuery} from "../../Interfaces/PostQuery";
import PostWidget from "../../Components/PostWidget/PostWidget";
import {IPost} from "../../Interfaces/IPost";
import ArrowButton from "../../Components/ArrowButton/ArrowButton";
import {
    hasLargeScreen,
    hasMediumScreen,
    hasSmallScreen
} from "../../Helpers/dimensions";
import {wp} from "../../Factories/Wordpress";
import { BarSearch } from "../../Components/BarSearch/BarSearch";

interface IProps {
    title: string,
    query: PostQuery
}

interface IState {
    isFetching: boolean
    scrolledItems: number
    maxItemsToScroll: number
    posts?: IPost[]
    page: number
    isDragging: boolean
    dragX: number
    startPosition: number
    stopPosition: number
    widgetWidth?: number
    search?: string
}

class PostBar extends React.Component<IProps, IState> {
    protected maxPostsOnBar = 12;
    private aborted = false;
    public abortController = new AbortController();

    constructor(props: IProps) {
        super(props);

        if (this.props.query.per_page) {
            this.maxPostsOnBar = this.props.query.per_page;
        }

        this.handleResize = this.handleResize.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.resetAndLoad = this.resetAndLoad.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.startDragging = this.startDragging.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.stopDragging = this.stopDragging.bind(this);
        this.preventVerticalScroll = this.preventVerticalScroll.bind(this);

        this.state = {
            isFetching: false,
            scrolledItems: 0,
            maxItemsToScroll: 0,
            page: 1,
            isDragging: false,
            dragX: 0,
            startPosition: 0,
            stopPosition: 0
        };
    }

    get posts() {
        if (!this.state.posts || this.state.isFetching) {
            return (new Array(4).fill(0)).map((value, index) => {
                return <PostWidget key={index}/>
            });
        }
        return this.state.posts.map((post: IPost) => {
            return <PostWidget key={post.id} post={post}/>
        })
    }

    get shouldShowArrowLeft() {
        return !this.state.isFetching && this.state.scrolledItems > 0;
    }

    get shouldShowArrowRight() {
        return !this.state.isFetching &&
            this.state.scrolledItems !== this.state.maxItemsToScroll &&
            this.state.posts &&
            this.state.posts.length > this.totalThatFitOnScreen;
    }

    componentDidMount(): void {
        this.loadMore();

        document.addEventListener('touchend', this.stopDragging);
        window.addEventListener('resize', this.handleResize);

        window.addEventListener('touchmove', this.preventVerticalScroll, {passive: false});
    }

    componentWillUnmount(): void {
        this.aborted = true;
        try {
            this.abortController.abort();
        } catch (e) {
            //
        }
        window.removeEventListener('resize', this.handleResize);

        window.removeEventListener('touchmove', this.preventVerticalScroll);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevState.search !== this.state.search) {
            this.resetAndLoad();
        }
    }

    moveLeft() {
        let items = this.state.scrolledItems;
        items--;

        if (items < 0) {
            this.setState({isDragging: false, dragX: 0});
            return;
        }

        this.setState({scrolledItems: items, isDragging: false, dragX: 0});
    }
    moveRight() {
        let items = this.state.scrolledItems;
        items++;

        if (items > this.state.maxItemsToScroll) {
            this.setState({isDragging: false, dragX: 0});
            return;
        }
        this.setState({scrolledItems: items, isDragging: false, dragX: 0});

        if (items >= this.state.maxItemsToScroll - 1) {
            this.loadMore();
        }
    }

    get totalThatFitOnScreen(): number {
        return hasSmallScreen() ? 2 : (hasMediumScreen() ? 2 : 4);
    }

    get maxReached(): boolean {
        if (!this.state.posts) {
            return false;
        }
        return this.posts.length === this.maxPostsOnBar;
    }

    get query() {
        const query = this.props.query;

        query.search = this.state.search;

        return query;
    }

    resetAndLoad() {
        this.abortController.abort();
        this.abortController = new AbortController();
        this.setState({posts: [], page: 1, scrolledItems: 0, isFetching: false}, this.loadMore);
    }
    loadMore() {
        if (this.maxReached || this.state.isFetching) {
            return;
        }
        this.setState({isFetching: true});

        const query = this.query;
        query.page = this.state.page;
        (wp().posts.get(query as any, this.abortController.signal) as Promise<IPost[]>).then((posts: IPost[]) => {
            if (this.aborted) {
                return;
            }
            const currentPosts = (this.state.posts ? this.state.posts : [] as IPost[]).concat(posts).map((post: IPost, index: number) => {
                post.id = index;
                return post;
            });
            this.setState({posts: currentPosts, isFetching: false, page: this.state.page + 1});
            this.setState({maxItemsToScroll: currentPosts.length - this.totalThatFitOnScreen});
        }).catch((e: any) => {
            //
        });
    }

    handleResize() {
        const total = this.posts.length;
        const totalThatFitOnScreen = this.totalThatFitOnScreen;
        this.setState({maxItemsToScroll: total - totalThatFitOnScreen, scrolledItems: 0});
    }

    startDragging(event: any) {
        if (event.target.classList.contains('button')) {
            return;
        }
        if (!this.state.isDragging) {
            this.setState({
                isDragging: true,
                startPosition: event.touches[0].clientX,
                stopPosition: event.touches[0].clientX,
                dragX: 0
            });
        }
    }
    handleDrag(event: any) {
        if (this.state.isDragging) {
            const current = event.touches[0].clientX;
            const dragX = current - this.state.startPosition;
            this.setState({dragX, stopPosition: current});
        }
    }
    stopDragging() {
        if (this.state.isDragging) {
            const start = this.state.startPosition;
            const end = this.state.stopPosition;
            const direction = start > end ? 'right' : 'left';
            const minimumDiff = window.outerWidth / 100 * 20;
            const diff = (start - end) < 0 ? (start - end) * -1 : (start - end);

            if (diff > minimumDiff) {
                if (direction === 'right') {
                    this.moveRight();
                } else {
                    this.moveLeft();
                }
            } else {
                const newState: any = {isDragging: false, dragX: 0};

                this.setState(newState);
            }
        }
    }

    preventVerticalScroll (e: any) {
        if(this.state.isDragging) { e.preventDefault(); e.returnValue = false; return false; }
    }


    get dragStyle () {
        if (!this.state.isDragging) {
            return {};
        }

        let width = '((100vw - 20px * 2 - (1 * 12.5px)) / 2)';
        let gap = 12.5;
        if (hasMediumScreen()) {
            width = '((100vw - 37px * 2 - (1 * 37px)) / 2)';
            gap = 37;
        } else if (hasLargeScreen()) {
            width = '((100vw - 50px * 2 - (3 * 37px)) / 4)';
            gap = 37;
        }

        return {transform: 'translateX(calc(' + this.state.scrolledItems + ' * -1 * (' + width + ' + ' + gap + 'px) + ' + this.state.dragX + 'px))'};
    }

    public handleSearch(s: string) {
        this.setState({search: s});
    }

    render() {
        return (
            <div className="post-bar-container">
                <div className="post-bar-heading-wrapper">
                    <h1>{this.props.title}</h1>
                    <BarSearch onSearch={this.handleSearch}/>
                </div>
                {!this.state.isFetching && this.state.posts && this.state.posts.length === 0 ? (
                    <>
                        <p><em>Er zijn helaas geen resultaten gevonden</em></p>
                    </>
                ) : <></>}
                <div
                    className={"post-bar-wrapper " + (this.state.isDragging ? 'is-dragging' : '')}
                    data-length={this.posts.length}
                    data-scrolled-items={this.state.scrolledItems}
                    onTouchStart={this.startDragging}
                    onTouchMove={this.handleDrag}
                >
                    <div
                        className="post-bar"
                        style={this.dragStyle}
                    >
                        {this.posts}
                    </div>
                </div>
                <ArrowButton direction="left" show={this.shouldShowArrowLeft} onClick={this.moveLeft} />
                <ArrowButton direction="right" show={this.shouldShowArrowRight} onClick={this.moveRight} />
            </div>
        );
    }
}

export default PostBar;