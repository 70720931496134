function getBreakpoints() {
    return {
        small: 0,
        medium: 640,
        large: 1024,
        xlarge: 1200,
        xxlarge: 1440,
    } as { [key: string]: number };
}
function getScreenWidth() {
    return window.outerWidth;
}
function getScreenHeight() {
    return window.outerHeight;
}
export function hasSmallScreen() {
    return getScreenWidth() < getBreakpoints().medium;
}
export function hasSmallScreenOrHigher() {
    return isScreenDimensionOrHigher('small');
}
export function hasMediumScreen() {
    return getScreenWidth() < getBreakpoints().large;
}
export function hasMediumScreenOrHigher() {
    return isScreenDimensionOrHigher('medium');
}
export function hasLargeScreen() {
    return getScreenWidth() < getBreakpoints().xlarge;
}
export function hasLargeScreenOrHigher() {
    return isScreenDimensionOrHigher('large');
}
export function hasXLargeScreen() {
    return getScreenWidth() < getBreakpoints().xxlarge;
}
export function hasXLargeScreenOrHigher() {
    return isScreenDimensionOrHigher('xlarge');
}
export function hasXXLargeScreen() {
    return getScreenWidth() >= getBreakpoints().xxlarge;
}
// function getScreenDimension() {
//     if (hasSmallScreen()) {
//         return 'small';
//     }
//     if (hasMediumScreen()) {
//         return 'medium';
//     }
//     if (hasLargeScreen()) {
//         return 'large';
//     }
//     if (hasXLargeScreen()) {
//         return 'xlarge';
//     }
//     return 'xxlarge';
// }
export function isScreenDimensionOrHigher(dimension: string) : boolean {
    let isDimension;
    let higherDimensions: string[];
    switch (dimension) {
        case 'small':
            higherDimensions = ['medium', 'large', 'xlarge', 'xxlarge'];
            isDimension = hasSmallScreen();
        break;
        case 'medium':
            higherDimensions = ['large', 'xlarge', 'xxlarge'];
            isDimension = hasMediumScreen();
        break;
        case 'large':
            higherDimensions = ['xlarge', 'xxlarge'];
            isDimension = hasLargeScreen();
        break;
        case 'xlarge':
            higherDimensions = ['xxlarge'];
            isDimension = hasXLargeScreen();
        break;
        case 'xxlarge':
        default:
            higherDimensions = [];
            isDimension = hasXXLargeScreen();
        break;
    }

    return isDimension || (() : boolean => {
        let isHigherDimension = false;
        higherDimensions.forEach((higherDimension) => {
            if (!isHigherDimension) {
                isHigherDimension = isScreenDimensionOrHigher(higherDimension);
            }
        });
        return isHigherDimension;
    })()

}
export function isPortrait() {
    return getScreenWidth() < getScreenHeight();
}
export function isLandscape() {
    return !isPortrait();
}