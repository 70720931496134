import React, {useEffect, useState} from "react";
import {ISlugResult, wp} from "../../../Factories/Wordpress";
import NotFound from "../../../Pages/NotFound/NotFound";
import {Post} from "../../../Pages/Post/Post";
import { Page } from "../../../Pages/Page/Page";

interface IProps {
    match: {params: {slug: string}}
}

export function SlugParser(props: IProps) {
    const [page, setPage] = useState();

    useEffect(() => {
        wp().slug.get({slug: props.match.params.slug}, undefined, 1).then((result: ISlugResult) => {
            if (result !== null && result.ID) {
                const match = {params: {slug: props.match.params.slug}};
                switch (result.type) {
                    case 'page':
                        setPage(<Page match={match} parent={false} />);
                    break;
                    case 'post':
                        setPage(<Post match={match} parent={false} />);
                    break;
                    default:
                        setPage(null);
                    break;
                }
            } else {
                setPage(<NotFound/>);
            }
        })
    }, [props.match.params.slug]);

    return (
        <>
            {page}
        </>
    );
}