import React, { ChangeEventHandler } from "react";

interface IProps {
    year?: number
    month?: number
    future?: boolean
    name?: string
    required?: boolean
    onChange?: ChangeEventHandler<any>
}

export function MonthSelect(props: IProps) {
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    return (
        <select
            onChange={props.onChange}
            defaultValue={props.month}
            required={props.required}
            name={props.name}
        >
            {months.map((monthName: any, index: number) => {
                if (props.future && props.year && props.year === (new Date()).getFullYear() && index < (new Date()).getMonth()) {
                    return undefined;
                }
                return <option key={index} value={index + 1}>{monthName}</option>
            })}
        </select>
    );
}