import React from "react";
import "./MenuButton.scss";

interface IProps {
    open: boolean
}

export function MenuButton(props: IProps) {
    const classes = ['menu-button'];
    if (props.open) {
        classes.push('open');
    }

    return (
        <div className={classes.join(' ')}>
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
        </div>
    );
}