import React  from "react";

interface IProps {
    min: number,
    max: number,
    onChange: any,
    value?: number|number[],
    range: boolean,
    step: number
}
interface IState {
    dragging?: string,
    fromPos: number|string,
    toPos: number|string,
    startPos?: number,
    offsetLeft: number,
    containerWidth: number,
    xCorrection: number,
    dragFrom: number,
    dragTo: number,
}

export class SliderInput extends React.Component<IProps, IState> {
    public container: HTMLDivElement|null = null;

    constructor(props: IProps) {
        super(props);

        let from = !!props.value && typeof props.value === 'number' ? props.value : props.min;
        let to = 0;
        if (this.props.range) {
            // @ts-ignore
            from = props.value && props.value[0] ? props.value[0] : 0;
            // @ts-ignore
            to = props.value && props.value[1] ? props.value[1] : this.max;
        }
        this.state = {
            fromPos: 0,
            toPos: 0,
            offsetLeft: 0,
            containerWidth: 0,
            xCorrection: 0,
            dragFrom: from,
            dragTo: to,
        };

        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
    }

    get min(): number {
        return this.props.min;
    }
    get max(): number {
        return this.props.max;
    }

    get from(): number {
        if (this.props.range && this.props.value) {
            // @ts-ignore
            return this.props.value[0];
        }
        if (!this.props.range && typeof this.props.value === 'number') {
            return this.props.value;
        }
        return 0;
    }
    get to(): number {
        if (!this.props.range) {
            return 0;
        }
        // @ts-ignore
        if (!this.props.value || !this.props.value[1]) {
            return this.max;
        }
        // @ts-ignore
        return this.props.value[1];
    }

    get value() {
        if (!this.props.range) {
            return this.state.dragFrom <= this.min ? this.min : this.state.dragFrom;
        }
        return [this.state.dragFrom === this.min ? null : this.state.dragFrom,
            this.state.dragTo === this.max ? null : this.state.dragTo];
    }

    componentDidMount(): void {
        this.setValues(this.from, this.to);
        setTimeout(() => {
            // Fix the offset on smaller screens
            this.setValues(this.from, this.to);
        }, 500);
        document.addEventListener('mousemove', this.handleDrag);
        document.addEventListener('touchmove', this.handleDrag);
        document.addEventListener('mouseup', this.handleDragEnd);
        document.addEventListener('touchend', this.handleDragEnd);
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.state.dragging && prevProps.value !== this.props.value) {
            this.setValues(this.from, this.to);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousemove', this.handleDrag);
        document.removeEventListener('touchmove', this.handleDrag);
        document.removeEventListener('mouseup', this.handleDragEnd);
        document.removeEventListener('touchend', this.handleDragEnd);
    }

    setValues(from: number, to: number) {
        if (this.container) {
            const offsetLeft = (this.container.getBoundingClientRect() as any).x;
            const containerWidth = this.container.clientWidth;

            this.setState({
                offsetLeft,
                containerWidth,
                fromPos: this.getPercentageOfValue(from),
                toPos: this.getPercentageOfValue(to)
            });
        }
    }

    getPercentageOfValue(value: number) {
        if (this.container) {
            const percentageBelow1 = value / this.max;
            return (this.container.clientWidth - 40) * percentageBelow1;
        }
        return 0;
    }

    handleDragStart(event: any): void {
        document.querySelectorAll('body')[0].classList.add('dragging');
        const target = (event.target as HTMLDivElement);
        const parent = target.parentElement;
        if (parent) {
            const left = (parent.getBoundingClientRect() as any).x;
            const width = parent.clientWidth;

            const boxLeft = (target.getBoundingClientRect() as any).x;
            const diff = this.getClientX(event) - boxLeft;

            const box = target.classList.contains('from') ? 'from' : 'to';

            this.setState({dragging: box, offsetLeft: left, containerWidth: width, xCorrection: diff});
        }
    }
    handleDragEnd(event: any): void {
        document.querySelectorAll('body')[0].classList.remove('dragging');
        if (this.state.dragging) {
            this.setState({dragging: undefined});
            this.props.onChange(this.value);
        }
    }
    handleDrag(event: any): void {
        if (!this.state || !this.state.dragging) {
            return;
        }
        const box = this.state.dragging;

        const maxPercentage = ((this.state.containerWidth - 40) / this.state.containerWidth * 100);

        let left = this.getClientX(event);
        left = left - this.state.offsetLeft;

        left = left - this.state.xCorrection;

        left = left / (this.state.containerWidth - 40) * 100;
        const percentage = left;

        left = left > maxPercentage ? maxPercentage : left;


        left = left < 0 ? 0 : (left > 100 ? 100 : left);

        let newValue = this.max * (percentage / 100);
        newValue = Math.round(newValue / this.props.step) * this.props.step;
        newValue = newValue < this.min ? this.min : (newValue > this.max ? this.max : newValue);
        const afterStateChange = () => {
            this.props.onChange(this.value);
        };
        if (box === 'from') {
            let newFrom = newValue;
            let newTo = 0;
            if (this.props.range) {
                newFrom = newValue > this.state.dragTo ? this.state.dragTo : newValue;
                newTo = newValue > this.state.dragTo ? newValue : this.state.dragTo;
            }
            this.setState({fromPos: (left + '%'), dragFrom: newFrom, dragTo: newTo}, afterStateChange);
        } else {
            const newFrom = newValue > this.state.dragFrom ? this.state.dragFrom : newValue;
            const newTo = newValue > this.state.dragFrom ? newValue : this.state.dragTo;
            this.setState({toPos: (left + '%'), dragTo: newTo, dragFrom: newFrom}, afterStateChange);
        }
    }

    getClientX(event: any): number {
        let x = event.clientX;
        if (!x && event.touches && event.touches) {
            x = event.touches[0].clientX;
        }
        return x;
    }

    render() {
        return <div className="range-input-wrapper"
            // onMouseMove={this.handleDrag.bind(this)}
            // onTouchMove={this.handleDrag.bind(this)}
                    ref={element => this.container = element}
        >
            <div className={"range-input-box from " + (this.state.dragging && this.state.dragging === "from" ? "dragging" : null)}
                 style={{left: this.state.fromPos}}
                 onMouseDown={this.handleDragStart.bind(this)}
                 onTouchStart={this.handleDragStart.bind(this)}
                 onMouseUp={this.handleDragEnd.bind(this)}
                 onTouchEnd={this.handleDragEnd.bind(this)}
            />
            {this.props.range ? (
                <div className={"range-input-box to " + (this.state.dragging && this.state.dragging === "to" ? "dragging" : null)}
                     style={{left: this.state.toPos}}
                     onMouseDown={this.handleDragStart.bind(this)}
                     onTouchStart={this.handleDragStart.bind(this)}
                     onMouseUp={this.handleDragEnd.bind(this)}
                     onTouchEnd={this.handleDragEnd.bind(this)}
                />
            ) : ''}
        </div>
    }
}