import React from "react";
import './TagBar.scss';

interface IProps {
    children: any;
    full?: boolean;
}
interface IState {
}

class TagBar extends React.Component<IProps, IState>{
    protected id: string = btoa((Math.floor(Math.random() * 20000) + 1).toString()).split('=').join('');

    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    get tags() {
        if (!this.props.children) {
            return undefined;
        }
        return (this.props.children[0] ? this.props.children : [this.props.children]);
    }

    get tagBar(): Element {
        const tagBar = document.getElementById(this.id);
        if (!tagBar) {
            return new Element();
        }
        return tagBar;
    }

    get className() {
        const classes = ['tag-bar'];

        if (!this.props.full) {
            classes.push('compact');
        }

        return classes.join(' ');
    }

    componentDidMount(): void {
    }
    componentWillUnmount(): void {
    }

    componentDidUpdate(): void {
    }

    render() {
        return (
            <div className={this.className} id={this.id}>
                {this.tags}
            </div>
        );
    }
}

export default TagBar;