import "./Pagination.scss";
import React from "react";
import ReactPaginate from "react-paginate";


interface IProps {
    length: number
    active?: number
    onPageChange?(selectedItem: { selected: number }): void
}

export function Pagination(props: IProps) {
    return (
        <ReactPaginate
            containerClassName={"pagination-wrapper"}
            onPageChange={props.onPageChange}
            pageCount={props.length}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            initialPage={props.active}
            forcePage={props.active}
            previousLabel={""}
            previousClassName={"prev-wrapper"}
            nextLabel={""}
            nextClassName={"next-wrapper"}
        />
    );
}