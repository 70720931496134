import React from "react";
import { IHouse } from "../../Interfaces/IHouse";
import Image from "../Image/Image";
import './HouseWidget.scss';
import {IHouseProperty} from "../../Interfaces/IHouseProperty";
import HouseTag from "../HouseTag/HouseTag";
import TagBar from "../../Containers/TagBar/TagBar";
import { getReadablePrice, randomBetween } from "../../Helpers/numbers";
import {NavLink} from "react-router-dom";
import placeholder from "../../resources/image-placeholder.svg";
import { randomString, randomWords } from "../../Helpers/strings";
import Tag from "../Tag/Tag";
import { FancyLabel } from "../FancyLabel/FancyLabel";

interface IProps {
    house?: IHouse
}
interface IState {

}
class HouseWidget extends React.Component<IProps, IState>{
    get thumbnail() {
        if (!this.props.house || !this.props.house.images || !this.props.house.images[0]) {
            return placeholder;
        }
        return this.props.house.images[0].thumbnail;
    }

    get imagefull() {
        if (!this.props.house || !this.props.house.images || !this.props.house.images[0]) {
            return undefined;
        }
        return this.props.house.images[0].full;
    }
    get title() {
        return this.props.house ? this.props.house.title : undefined;
    }
    get readablePrice() {
        if (!this.props.house) {
            return <span>&euro; {getReadablePrice(randomBetween(100000, 1000000))}</span>;
        }
        return <span>&euro; {getReadablePrice(this.props.house.price)}</span>;
    }
    get properties() {
        if (!this.props.house || !this.props.house.properties) {
            return (new Array(randomBetween(2, 4))).fill(0).map((nothing: number, index: number) => {
                const word = randomString(5, 15);
                return <Tag key={index}>{word}</Tag>
            });
        }
        return this.props.house.properties.map((property: IHouseProperty, index: number) => {
            return <HouseTag key={index} property={property} />
        });
    }

    get url() {
        if (!this.props.house) {
            return '/';
        }
        return this.props.house.url;
    }

    get overlay() {
        if (!this.props.house || !this.props.house.banner || this.props.house.banner.length === 0) {
            return undefined;
        }
        return <FancyLabel text={this.props.house.banner} colorType="red" onImage={true}/>;
    }

    get className() {
        const classes = ['house-widget'];

        if (!this.props.house) {
            classes.push('is-loading');
        }

        return classes.join(' ');
    }

    render() {
        return (
            <NavLink className={this.className} to={this.url}>
                <Image src={this.imagefull} thumbnail={this.thumbnail} alt={this.title} overlay={this.overlay} />

                <div className="title-wrapper">
                    <h2>{this.title ? this.title : <span>{randomWords(25, 10)}</span>}</h2>
                    <h2 className="medium-text-right">{this.readablePrice}</h2>
                </div>
                <TagBar>{this.properties}</TagBar>
            </NavLink>
        );
    }
}
export default HouseWidget;