import { randomBetween } from "./numbers";

export function ucFirstEachWord(str: string) {
    return str
        .split(' ')
        .map((word: string) => ucFirst(word))
        .join(' ');
}
export function ucFirst(str: string) {
    return str
        .substr(0, 1)
        .toUpperCase()
        +
        str.substr(1).toLowerCase()
}
export function randomString(minLength: number, maxLength: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const max = randomBetween(minLength, maxLength);
    for ( let i = 0; i < max; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
export function randomWords(length: number, minLength?: number) {
    const words: string[] = [];

    const max = !minLength ? length : randomBetween(length, minLength);

    while(words.join(' ').length < max) {
        words.push(randomString(4, 12));
    }

    return words.join(' ');
}