import React, {useState} from "react";
import {wp} from "../../../Factories/Wordpress";
import Input from "../../../Components/Input/Input";
import {LoadingIndicator} from "../../../Components/LoadingIndicator/LoadingIndicator";
import {Error} from "../../../Components/Error/Error";

interface IProps {
    onLogin: () => void
}

export function Login(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');


        wp().auth.login({username: email, password: password}).then((response: any) => {
            setIsLoading(false);
            if (response.token) {
                props.onLogin();
            } else {
                throw response;
            }
        }).catch((error: any) => {
            setError('Gegevens onjuist');
            setIsLoading(false);
        });
    };



    return (
        <div className="login-wrapper">
            <h1>Inloggen.</h1>
            {isLoading ? <LoadingIndicator contained={false} /> : ''}
            {error ? <Error>{error}</Error> : ''}

            <form onSubmit={handleSubmit}>
                <Input label="Uw email" required={true}>
                    <input type="email" placeholder="hendrik@huis.nl" onChange={(event) => setEmail(event.target.value)}/>
                </Input>
                <Input label="Uw wachtwoord" required={true}>
                    <input type="password" placeholder="MijnWoning123" onChange={(event) => setPassword(event.target.value)}/>
                </Input>
                <div className="text-center">
                    <button className="button secondary" type="submit">Inloggen</button>
                </div>
            </form>
        </div>
    );
}