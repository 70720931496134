import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Topbar from "../../Components/Topbar/Topbar";
import Frontpage from "../../Pages/Frontpage/Frontpage";
import { ResetPassword } from "../../Pages/Profile/ResetPassword/ResetPassword";
import { Houses } from "../../Pages/Houses/Houses";
import Profile from "../../Pages/Profile/Profile";
import { House } from "../../Pages/House/House";
import { Post } from "../../Pages/Post/Post";
import { PostArchive } from "../../Pages/PostArchive/PostArchive";
import { SlugParser } from "../Router/SlugParser/SlugParser";
import Footer from "../Footer/Footer";
import { wp } from "../../Factories/Wordpress";
import { IProvince } from "../../Interfaces/IProvince";
import { ICity } from "../../Interfaces/ICity";
import { City } from "../../Pages/City/City";
import { Province } from "../../Pages/Province/Province";
import { HelmetTitle } from "../../Components/HelmetTitle/HelmetTitle";
import { PropositionForm } from "../../Components/Proposition/PropositionForm/PropositionForm";

ReactGA.initialize('UA-55621360-1');

interface IProps {
    history?: any
}
interface ILocation {
    hash: string
    key: string
    pathname: string
    search: string
    state: any
}

function AppContainer({history}: IProps) {
    const [locationRoutes, setLocationRoutes] = useState<any>(null);
    const [url, setUrl] = useState<string>('');


    useEffect(() => {
        if (!locationRoutes) {
            wp().locations.get().then((provinces: IProvince[]) => {
                setLocationRoutes(provinces.map((province: IProvince, index: number) => {
                    const provincePath = '/aanbod/' + province.slug;
                    const children = province.cities.map((city: ICity) => {
                        const path = provincePath + '/' + city.slug;
                        return <Route key={city.term_id} exact path={path} component={City}/>;
                    });
                    return [<Route exact path={provincePath} component={Province}/>, children];
                }));
            });
        }
    }, [locationRoutes]);

    useEffect(() => {
        const onChange = (location: ILocation, action: string) => {
            ReactGA.pageview(window.location.pathname + window.location.search);
            const parts = location.pathname.split('/').filter((word: string) => word.length > 0);

            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', parts.join(' '));

            setUrl(window.location.toString());
        };

        onChange({
            hash: "",
            key: "",
            pathname: '/' + document.location.href.toString().split('/').filter((t: string, index: number) => index > 2).join('/'),
            search: "",
            state: null
        }, 'PUSH');

        return history.listen(onChange);
    }, [history]);

    if (url.split('planvoorstel-iframe').length > 1) {
        return <PropositionForm show={true} />;
    }

    return (
        <div>
            <HelmetTitle url={url} />
            <Topbar/>
            <Switch>
                <Route exact path="/" component={Frontpage}/>
                <Route exact path="/resetpass" component={ResetPassword}/>
                <Route exact path="/aanbod" component={Houses}/>
                <Route path="/profiel" component={Profile}/>
                {locationRoutes}
                <Route path="/aanbod/:slug" component={House}/>
                <Route path="/informatie/:slug" component={Post}/>
                <Route path="/informatie" component={PostArchive}/>
                <Route path="/:slug" component={SlugParser}/>
            </Switch>
            <Footer/>
        </div>
    )
}
// @ts-ignore
export default withRouter(AppContainer);
