import React from "react";
import { ICity } from "../../Interfaces/ICity";
import { IProvince } from "../../Interfaces/IProvince";
import LocationWidget from "../../Components/LocationWidget/LocationWidget";
import { CityQuery } from "../../Interfaces/CityQuery";
import "./LocationBar.scss";
import ArrowButton from "../../Components/ArrowButton/ArrowButton";
import {
    hasLargeScreen,
    hasMediumScreen,
    hasSmallScreen,
    hasXLargeScreen,
    hasXXLargeScreen
} from "../../Helpers/dimensions";
import {wp} from "../../Factories/Wordpress";
import { BarSearch } from "../../Components/BarSearch/BarSearch";

interface IProps {
    title: string,
    query: CityQuery
}
interface IState {
    isFetching: boolean
    scrolledItems: number
    maxItemsToScroll: number
    locations?: (ICity | IProvince)[]
    allLocations?: (ICity | IProvince)[]
    isDragging: boolean
    dragX: number
    startPosition: number
    stopPosition: number
    widgetWidth?: number
}

class LocationBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.startDragging = this.startDragging.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.stopDragging = this.stopDragging.bind(this);
        this.preventVerticalScroll = this.preventVerticalScroll.bind(this);

        this.state = {
            isFetching: false,
            scrolledItems: 0,
            maxItemsToScroll: 0,
            isDragging: false,
            dragX: 0,
            startPosition: 0,
            stopPosition: 0
        };
    }

    get locations() {
        if (!this.state.locations) {
            return (new Array(8)).fill(0).map((nothing: number, index: number) => {
                return <LocationWidget key={index} />
            });
        }
        return this.state.locations.map((location: (ICity | IProvince)) => {
            return <LocationWidget location={location} key={location.term_id} />
        });
    }

    get shouldShowArrowLeft() {
        return !this.state.isFetching && this.state.scrolledItems > 0;
    }

    get shouldShowArrowRight() {
        return !this.state.isFetching && this.state.scrolledItems !== this.state.maxItemsToScroll;
    }

    get totalThatFitOnScreen(): number {
        return hasSmallScreen() ? 3 : (hasMediumScreen() ? 4 : (hasLargeScreen() ? 5 : (hasXLargeScreen() ? 6 : 7)));
    }

    moveLeft() {
        let items = this.state.scrolledItems;
        items--;

        if (items < 0) {
            this.setState({isDragging: false, dragX: 0});
            return;
        }

        this.setState({scrolledItems: items, isDragging: false, dragX: 0});
    }
    moveRight() {
        let items = this.state.scrolledItems;
        items++;

        this.setState({isDragging: false, dragX: 0});
        if (items > this.state.maxItemsToScroll) {
            return;
        }
        this.setState({scrolledItems: items, isDragging: false, dragX: 0});
    }

    componentDidMount(): void {
        this.setState({isFetching: true});
        wp().cities.get().then((cities: ICity[]) => {
            this.setState({locations: cities, allLocations: cities, maxItemsToScroll: cities.length - this.totalThatFitOnScreen, isFetching: false});
        });
        document.addEventListener('touchend', this.stopDragging);
        window.addEventListener('touchmove', this.preventVerticalScroll, {passive: false});
    }

    componentWillUnmount(): void {
        window.removeEventListener('touchmove', this.preventVerticalScroll);
    }

    handleResize() {
        const total = this.locations.length;
        const totalThatFitOnScreen = this.totalThatFitOnScreen;
        this.setState({maxItemsToScroll: total - totalThatFitOnScreen, scrolledItems: 0});
    }

    startDragging(event: any) {
        if (event.target.classList.contains('button')) {
            return;
        }
        if (!this.state.isDragging) {
            this.setState({
                isDragging: true,
                startPosition: event.touches[0].clientX,
                stopPosition: event.touches[0].clientX,
                dragX: 0
            });
        }
    }
    handleDrag(event: any) {
        if (this.state.isDragging) {
            const current = event.touches[0].clientX;
            const dragX = current - this.state.startPosition;
            this.setState({dragX, stopPosition: current});
        }
    }
    stopDragging() {
        if (this.state.isDragging) {
            const start = this.state.startPosition;
            const end = this.state.stopPosition;
            const direction = start > end ? 'right' : 'left';
            const minimumDiff = window.outerWidth / 100 * 20;
            const diff = (start - end) < 0 ? (start - end) * -1 : (start - end);

            if (diff > minimumDiff) {
                if (direction === 'right') {
                    this.moveRight();
                } else {
                    this.moveLeft();
                }
            } else {
                const newState: any = {isDragging: false, dragX: 0};

                this.setState(newState);
            }
        }
    }

    preventVerticalScroll (e: any) {
        if(this.state.isDragging) { e.preventDefault(); e.returnValue = false; return false; }
    }

    public handleSearch(s: string) {
        if (!this.state.allLocations) {
            return;
        }

        this.setState({
            scrolledItems: 0,
            locations: this.state.allLocations.slice().filter((city: ICity|IProvince) => {
                return city.name.toLowerCase().split(s.toLowerCase()).length > 1;
            }).sort((a, b) => (a.name > b.name) ? 1 : -1)
        })
    }


    get dragStyle () {
        if (!this.state.isDragging) {
            return {};
        }

        let width = '(' + this.state.scrolledItems + ' * -1 * (100vw - 20px * 2 - (1 * 12.5px) / 2) + 12.5px)';
        if (hasMediumScreen()) {
            width = '(' + this.state.scrolledItems + ' * -1 * (100vw - 37px * 2 - (3 * 37px) / 4) + 37px)';
        } else if (hasLargeScreen()) {
            width = '(' + this.state.scrolledItems + ' * -1 * (100vw - 50px * 2 - (4 * 37px) / 5) + 37px)';
        } else if (hasXLargeScreen()) {
            width = '(' + this.state.scrolledItems + ' * -1 * (100vw - 50px * 2 - (5 * 37px) / 6) + 37px)';
        } else if (hasXXLargeScreen()) {
            width = '(' + this.state.scrolledItems + ' * -1 * (100vw - 50px * 2 - (6 * 37px) / 7) + 37px)';
        }

        return {transform: 'translateX(calc(' + width + ' + ' + this.state.dragX + 'px))'};
    }

    render() {
        return (
            <div className="location-bar-container">

                <div className="location-bar-heading-wrapper">
                    <h1>{this.props.title}</h1>
                    <BarSearch onSearch={this.handleSearch}/>
                </div>

                <div
                    className={"location-bar-wrapper " + (this.state.isDragging ? 'is-dragging' : '')}
                    data-length={this.locations.length}
                    data-scrolled-items={this.state.scrolledItems}
                    // onTouchStart={this.startDragging}
                    // onTouchMove={this.handleDrag}
                >
                    <div
                        className="location-bar"
                         // style={this.dragStyle}
                    >
                        {this.locations}
                    </div>
                </div>

                <ArrowButton direction="left" show={this.shouldShowArrowLeft} onClick={this.moveLeft} />
                <ArrowButton direction="right" show={this.shouldShowArrowRight} onClick={this.moveRight} />
            </div>
        );
    }
}

export default LocationBar;