import React from 'react';
import AppContainer from "../AppContainer/AppContainer";
import { BrowserRouter as ReactRouter } from 'react-router-dom';

interface IProps {

}

interface IState {
}

class Router extends React.Component<IProps, IState> {

    render() {
        return <ReactRouter>
            <AppContainer/>
        </ReactRouter>
    }
}

export default Router;