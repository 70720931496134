import React from "react";
import Tag from "../Tag";
import TagBar from "../../../Containers/TagBar/TagBar";
import "./TagInput.scss";

interface IProps {
    onSearch: (search: string) => Promise<any>
    onChange: (selection: number[], names?: string[]) => void
    value?: number[]
}
interface IState {
    options?: IOption[]
    selection: number[],
}
interface IOption {
    id: number
    name: string
}

export class TagInput extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            selection: this.props.value ? this.props.value : [],
        };
    }

    componentDidMount(): void {
        this.onSearch()
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.onSearch !== prevProps.onSearch) {
            this.onSearch();
        }
    }

    get selectedTags() {
        if (!this.state.options) {
            return [];
        }
        return this.state.options
            .filter((option: IOption) => {
                return this.state.selection.includes(option.id);
            })
            .map((option: IOption, index: number) => {
                return <Tag
                    primary={true}
                    key={index}
                    onClick={() => {
                        this.onRemove(option.id);
                    }}
                >{option.name}</Tag>;
            }
        );
    }

    get options() {
        if (!this.state.options) {
            return [];
        }
        return this.state.options
            .filter((option: IOption) => {
                return !this.state.selection.includes(option.id);
            })
            .map((option: IOption, index: number) => {
                return <Tag
                    hollow={true}
                    key={index}
                    onClick={() => {
                        this.onAdd(option.id);
                    }}
                >{option.name}</Tag>;
            }
        );
    }

    onSearch(value: string = '') {
        this.props.onSearch(value).then((answer: IOption[]) => {
            this.setState({options: answer});
        });
    }

    onAdd(id: number) {
        const selection = this.state.selection;
        selection.push(id);
        this.setState({selection}, () => {
            const names = !this.state.options ? [] : this.state.options.filter((option: IOption) => {
                return selection.includes(option.id);
            }).map((option: IOption) => {
                return option.name;
            });
            this.props.onChange(selection, names);
        });
    }

    onRemove(id: number) {
        const selection = this.state.selection.filter((foundId: number, index: number) => {
            return id !== foundId;
        });

        const names = !this.state.options ? [] : this.state.options.filter((option: IOption) => {
            return selection.includes(option.id);
        }).map((option: IOption) => {
            return option.name;
        });

        this.setState({selection}, () => {
            this.props.onChange(selection, names);
        });
    }

    render() {
        return (
            <div className="tag-input">
                <input
                    type="text"
                    onChange={event => this.onSearch(event.target.value)}
                    placeholder="Zoeken..."
                />
                <TagBar full={true}>{this.selectedTags} {this.options}</TagBar>
            </div>
        );
    }
}