import "./BarSearch.scss";
import React, { useEffect, useState } from "react";
import Input from "../Input/Input";

interface IProps {
    onSearch?: (s: string) => void
}

export function BarSearch(props: IProps) {
    let elm: HTMLDivElement|null = null;
    let input: HTMLInputElement|null = null;
    const [parent, setParent] = useState<HTMLDivElement|null>(null);

    // Set the parent
    useEffect(() => {
        if (elm && !parent) {
            (elm.parentElement as HTMLDivElement).classList.add('bar-search-wrapper');
            setParent(elm.parentElement as HTMLDivElement);
        }
    }, [elm, parent]);

    const handleIconClick = () => {
        if (!parent || !input) {
            return;
        }
        if (parent.classList.contains('search-open')) {
            parent.classList.remove('search-open');
            input.blur();
        } else {
            parent.classList.add('search-open');
            input.focus();
        }
    };

    return (
        <div
            className={"bar-search"}
            ref={thisElm => elm = thisElm}
        >
            <h1 className="icon-wrapper" onClick={handleIconClick}>
                <span className="icon-search" />
            </h1>
            <div className="bar-input-wrapper">
                <Input label={''}>
                    <input
                        type="text"
                        placeholder={"Zoeken..."}
                        onBlur={handleIconClick}
                        onChange={(event) => {
                            if (props.onSearch) {
                                props.onSearch(event.target.value);
                            }
                        }}
                        ref={thisInput => input = thisInput}
                    />
                </Input>
            </div>
        </div>
    );
}