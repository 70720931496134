import React from 'react';
import './App.scss';
import Router from "./Containers/Router/Router";
import {wp} from "./Factories/Wordpress";

const App: React.FC = () => {
  if (window.localStorage.getItem('x-user-token')) {
    wp().auth.validate()
        .catch((response: any) => {
          if (response.status === 403) {
            window.localStorage.removeItem('x-user-token');
            window.location.reload();
          }
        });
  }

  return (
      <Router/>
  );
};

export default App;
