import React from "react";
import {PageImage} from "../../Components/PageImage/PageImage";
import { PageContent } from "../../Components/PageContent/PageContent";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import PostBar from "../../Containers/PostBar/PostBar";
import {PostQuery} from "../../Interfaces/PostQuery";
import HouseBar from "../../Containers/HouseBar/HouseBar";
import {HouseQuery} from "../../Interfaces/HouseQuery";
import { LoadingIndicator } from "../../Components/LoadingIndicator/LoadingIndicator";
import { IPost } from "../../Interfaces/IPost";
import {wp} from "../../Factories/Wordpress";
import {NavLink} from "react-router-dom";
import { Login } from "../../Containers/Auth/Login/Login";
import placeholder from "../../resources/image-placeholder.svg";


interface IProps {
    match: {params: {slug: string}}
    parent: false
}
interface IState {
    page?: IPost
}

export class Page extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }
    get query() {
        return {
            slug: this.props.match.params.slug
        } as HouseQuery
    }
    get housesQuery() {
        return {
            meta: [{
                key: 'zw_on_frontpage',
                value: 1
            }],
            per_page: 4
        } as HouseQuery
    }
    get pagesQuery() {
        return {
            post_type: 'page',
            category_name: 'Informatie',
            limit: 4
        } as PostQuery
    }

    get title () {
        if (this.state.page) {
            if (this.state.page.slug === 'login') {
                return null;
            }
            const html = {__html: this.state.page.title};
            return <h1 dangerouslySetInnerHTML={html} />
        }
        return null;
    }
    get content () {
        if (this.state.page) {
            if (this.state.page.slug === 'login') {
                return <Login onLogin={() => {}}/>
            }
            return this.state.page.content;
        }
        return null;
    }


    get breadcrumbs() {
        if (!this.state.page) {
            return null;
        }

        const crumbs = [];

        crumbs.push(
            <li>
                <NavLink to={this.props.match.params.slug}>{this.state.page.title}</NavLink>
            </li>);

        return crumbs;
    }

    get image() {
        if (this.state.page && this.state.page.thumbnail) {
            return this.state.page.thumbnail;
        }
        return placeholder;
    }

    get contents() {
        if (this.state.page) {
            return (
                <div className="house-wrapper">
                    <ScrollToTopOnMount/>
                    <PageImage small={true} src={this.image} />
                    <PageContent className="regular-padding" crumbs={this.breadcrumbs} title={this.state.page.title}>
                        {this.content}
                    </PageContent>
                    <PostBar title="Wellicht interessant." query={this.pagesQuery}/>
                    <HouseBar title="Bekijk ook deze woningen." query={this.housesQuery} delayed={true}/>
                </div>
            );
        }
        return <LoadingIndicator />
    }

    componentDidMount(): void {
        this.retrieve();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if ((!this.state.page && prevProps.match.params.slug.length > 0) || prevProps.match.params.slug !== this.props.match.params.slug) {
            this.retrieve();
        }
    }

    retrieve() {
        wp().page.get(this.query as {}, undefined, 1).then((page: IPost) => {
            this.setState({page});
            window.scrollTo(0,0);
        });
    }

    render() {
        return (
            <div className="page-wrapper">
                <ScrollToTopOnMount/>
                {this.contents}
            </div>
        );
    }
}