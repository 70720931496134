import "./Choice.scss";
import React, { ChangeEventHandler } from "react";

interface IProps {
    options: { [key: string]: string }
    name?: string
    onChange?: ChangeEventHandler<any>
    label?: string
}

export function Choice(props: IProps) {
    const name = props.name ? props.name : btoa(new Date().getTime().toString());

    return (
        <div className="choice-wrapper">
            {props.label ? <span className={"label-span"}>{props.label}</span> : undefined}
            <div className="options-wrapper" data-has={Object.keys(props.options).length}>
                {Object.keys(props.options).map((key: string) => {
                    const id = name + '_' + key;
                    return <div className="option-wrapper" key={key}>
                        <input type="radio" id={id} name={name} value={key} onChange={props.onChange} required={true}/>
                        <label htmlFor={id}>{props.options[key]}</label>
                    </div>
                })}
            </div>
        </div>
    );
}