import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface IProps {
    tempTitle?: string
    url: string
}

const titles: {[url: string]: string} = {};

const abortController = new AbortController();

export function HelmetTitle(props: IProps) {
    const [title, setTitle] = useState<string|undefined>(props.tempTitle);


    let url = props.url;
    url = url.split('localhost').join('uwcasa.local');
    url = url.split('http://').join('https://');
    url = url.split('://').join('://static.');
    url = url.split('uwcasa.be').join('uwcasa.nl');
    url = url.split(':3000').join('');
    url = url.split(':3002').join('');
    url = url.split('beta.').join('');
    url = url.split('static.uwcasa.local').join('static.uwcasa.nl');

    useEffect(() => {
        if (titles[url]) {
            setTitle(titles[url]);
        } else {
            fetch(url, {
                signal: abortController.signal,
            })
                .then((result: Response) => result.text())
                .then((html: string) => {

                    const parsed = html.split("<title>")[1].split('</title>')[0].trim();
                    setTitle(parsed);
                    if (!titles[url]) {
                        titles[url] = parsed;
                    }
                });
        }
        return () => {
            // abortController.abort();
        }
    }, [url]);

    if (!title) {
        return <></>;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </HelmetProvider>
    )
}