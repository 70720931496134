import React, {ReactNode, useState} from "react";
import "./FoldableContent.scss";
import { NavLink } from "react-router-dom";

interface IProps {
    children: ReactNode|ReactNode[]|string
    moreInfoLink?: string
}

export function FoldableContent(props: IProps) {
    const [open, setOpen] = useState(false);

    const classes = ['foldable-content-wrapper'];
    if (open) {
        classes.push('open');
    }

    const buttonText = open ? 'Dichtklappen' : 'Lees meer';


    return (
        <div className={classes.join(' ')}>
            <div className="foldable-content">
                {props.children}
            </div>
            <div className="toggle-wrapper">
                <button onClick={() => setOpen(!open)}>{buttonText}</button>
                {props.moreInfoLink ? <NavLink to={props.moreInfoLink}>Meer info</NavLink> : <></>}
            </div>
        </div>
    );
}