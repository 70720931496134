import React from "react";
import {HouseFiltersWrapper} from "../../Containers/HouseFiltersWrapper/HouseFiltersWrapper";
import './HouseFilters.scss';
import TagBar from "../../Containers/TagBar/TagBar";
import Tag from "../Tag/Tag";
import {FiltersForm} from "../FiltersForm/FiltersForm";
import {Filters, IFilterIndicator} from "../../Core/Filters/Filters";
import {RemoveButton} from "../RemoveButton/RemoveButton";

interface IProps {
    onChange: any,
    inline?: boolean
}
interface IState {
    showFiltersForm: boolean,
    changingFilter: string,
    filters: IFilterIndicator[],
}

export class HouseFilters extends React.Component<IProps, IState>{
    public wait: any;
    constructor(props: IProps) {
        super(props);

        this.state = {showFiltersForm: false, filters: Filters.getFilterIndicators(), changingFilter: 'type'};

        this.openFilter = this.openFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleFormClose = this.handleFormClose.bind(this);
    }

    get filters() {
        return this.state.filters
            .filter((filter: IFilterIndicator) => {
                if (this.props.inline) {
                    return filter.key !== 'location';
                }
                return true;
            })
            .map((filter: IFilterIndicator, index: number) => {
                const isSet = Filters.isset(filter.key);
                const str = isSet ? filter.value : filter.name;
                const removeButton = <RemoveButton onClick={(e: any) => {
                    e.stopPropagation();
                    Filters.remove(filter.key);

                    this.handleFilterChange(Filters.getFilterIndicators());
                }}/>;

                let contents = <>{str}</>;
                if (isSet) {
                    contents = <>{str} {removeButton}</>;
                }

                return <Tag
                    key={index}
                    primary={isSet}
                    hollow={!isSet}
                    onClick={() => this.openFilter(filter.key)}
                >{contents}</Tag>
            })
    }

    openFilter(key: string) {
        this.setState({
            showFiltersForm: true,
            changingFilter: key
        });
    }

    handleFilterChange(filters: IFilterIndicator[]) {
        this.setState({filters});
        if (this.wait) {
            clearTimeout(this.wait);
        }
        this.wait = setTimeout(this.props.onChange, 1000);
    }

    handleFormClose() {
        this.setState({
            showFiltersForm: false,
        });
    }



    render() {
        return (
            <React.Fragment>
                <FiltersForm show={this.state.showFiltersForm} onClose={this.handleFormClose} changingFilter={this.state.changingFilter} onChange={this.handleFilterChange} />
                <HouseFiltersWrapper inline={this.props.inline}>
                    <div>
                        <div className="filters-container">
                            <h1>Stel uw filters in.</h1>
                            <TagBar full={true}>{this.filters}</TagBar>
                        </div>
                    </div>
                </HouseFiltersWrapper>
            </React.Fragment>
        );
    }
}