import React from 'react';
import './PictureSlider.scss';
import {IFrontImage} from "../../Interfaces/IFrontImage";
import {wp} from "../../Factories/Wordpress";

interface IProps {
}
interface IState {
    images: IImage[],
    loadedImages: IImage[],
    loadedHqImages: IImage[],
    parallaxOffset: number
}
interface IImage {
    show: boolean,
    url: string,
    lq: string,
}

let pictureTimeout: any;

class PictureSlider extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {images: [], parallaxOffset: 0, loadedImages: [], loadedHqImages: []};

        this.handleScroll = this.handleScroll.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
    }
    get pictures() {
        return this.state.loadedImages.map((image, index) => {
            let url = image.lq;
            if (this.state.loadedHqImages[index]) {
                url = this.state.loadedHqImages[index].url;
            }
            return (
                <li key={index} className={image.show ? 'show' : ''} >
                    <div style={{backgroundImage: 'url(' + url + ')'}} />
                </li>
            )
        })
    }

    get loadingImage() {
        if (this.state.loadedImages.length !== this.state.images.length) {
            const img = this.state.images[this.state.loadedImages.length];
            return <img src={img.lq} onLoad={() => {this.onImageLoad(img)}} alt="tmp-thumb" />
        } else if (this.state.loadedHqImages.length !== this.state.images.length) {
            const img = this.state.images[this.state.loadedHqImages.length];
            return <img src={img.url} onLoad={() => {this.onImageLoad(img, true)}} alt="tmp" />
        }
        return null;
    }

    onImageLoad(img: IImage, full: boolean = false) {
        const images = full ? this.state.loadedHqImages : this.state.loadedImages;
        images.push(img);
        if (images.length === 1) {
            img.show = true;
        }
        if (full) {
            this.setState({loadedHqImages: images});
        } else {
            this.setState({loadedImages: images});
        }
    }

    initPictures() {
        return wp().frontImages.get()
            .then((images: IFrontImage[]) => {
                const newImages = JSON.parse(JSON.stringify(images));
                for (let i = newImages.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [newImages[i], newImages[j]] = [newImages[j], newImages[i]];
                }

                this.setState({images: newImages.map((image: IFrontImage) => {
                        return {
                            show: false,
                            url: image.full,
                            lq: image.small
                        } as IImage
                    }).splice(0, 10)});
            })

    }

    initPictureInterval() {
        pictureTimeout = setInterval(() => {
            let images = this.state.images;
            const numberOfShownImages = images.filter((image: IImage) => image.show).length;
            if (numberOfShownImages > 1) {
                let lastShowImageIndex = 0;
                images.forEach((image: IImage, index: number) => {
                    if (image.show) {
                        lastShowImageIndex = index;
                    }
                });
                images[lastShowImageIndex].show = false;
            } else {
                if (!images[images.length - 1]) {
                    return;
                }
                images[images.length - 1].show = true;

                pictureTimeout = setTimeout(() => {
                    images = images.map((image: IImage) => {
                        image.show = true;
                        return image;
                    });
                    this.setState({images});
                },1000);
            }
            this.setState({images});
        }, 10000);
    }

    componentDidMount(): void {
        this.initPictures().then(() => {
            this.initPictureInterval();

            window.addEventListener('scroll', this.handleScroll);
        });
    }
    componentWillUnmount(): void {
        clearTimeout(pictureTimeout);

        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event: Event) {
        const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;


        this.setState({parallaxOffset: scrollTop / 4});
    }

    render() {
        return (
            <div className={"picture-slider-container"}>
                <ul className="picture-slider" style={{transform: 'translateY(' + this.state.parallaxOffset + 'px)'}} >
                    {this.pictures}
                </ul>
                <div className="images-loader">
                    {this.loadingImage}
                </div>
            </div>
        );
    }
}

export default PictureSlider;