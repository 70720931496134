import React, {useState} from "react";
import {ITokenSuccessResponse} from "squde-wp-api/ITokenResponses";
import Input from "../../../Components/Input/Input";
import {wp} from "../../../Factories/Wordpress";
import {IUserUpdateRequest} from "squde-wp-api/IUser";
import {LoadingIndicator} from "../../../Components/LoadingIndicator/LoadingIndicator";
import {Error} from "../../../Components/Error/Error";
import {Snackbar} from "../../../Components/Snackbar/Snackbar";

interface IProps {
    userDetails?: ITokenSuccessResponse
    reset?: boolean,
    onSuccess?: () => void
}

export function EditPassword(props: IProps) {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarType, setSnackbarType] = useState<"error"|"success">('success');

    const handleSubmit = (e: any) => {
        setError(undefined);
        e.preventDefault();

        if (password === passwordConfirm && password.length > 0) {
            setIsLoading(true);

            let request;
            if (!props.reset) {
                request = wp().user.update({password} as IUserUpdateRequest);
            } else {
                const key = (window.location.href.toString().split('?key=')[1]).split('&')[0];
                const user_login = window.location.href.toString().split('&login=')[1];
                request = wp().auth2.resetPassword({password, key, user_login});
            }

            request.then(() => {
                setIsLoading(false);
                setSnackbarText('Wachtwoord met succes gewijzigd.');
                setSnackbarType('success');

                if (props.onSuccess) {
                    props.onSuccess();
                }

            }).catch((e: any) => {
                setIsLoading(false);
                setSnackbarText('Er ging iets fout. Probeer het opnieuw.');
                setSnackbarType('error');
            });
        } else {
            if (password !== passwordConfirm) {
                setError('De wachtwoorden moeten gelijk zijn om op te kunnen slaan.')
            } else if (password.length > 0) {
                setError('U heeft geen nieuw wachtwoord ingevoerd')
            }
        }
    };

    return (
        <>
            {isLoading ? <LoadingIndicator /> : ''}
            <Snackbar type={snackbarType}>{snackbarText}</Snackbar>
            <h1>Wachtwoord {props.reset ? 'resetten': 'wijzigen'}.</h1>
            {error ? <Error>{error}</Error> : ''}
            <form onSubmit={handleSubmit} noValidate={true}>
                <Input label="Nieuw wachtwoord" required={true}>
                    <input type="password" placeholder="Uw veilige wachtwoord" onChange={(event) => setPassword(event.target.value)}/>
                </Input>
                <Input label="Nieuw wachtwoord (ter controle)" required={true}>
                    <input type="password" placeholder="Precies hetzelfde" onChange={(event) => setPasswordConfirm(event.target.value)}/>
                </Input>
                <div className="text-center">
                    <button type="submit" className="button secondary">Opslaan</button>
                </div>
            </form>
        </>
    );
}