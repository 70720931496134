import React from "react";
import { IPost } from "../../Interfaces/IPost";
import Image from "../Image/Image";
import './PostWidget.scss';
import { NavLink } from "react-router-dom";
import placeholder from "../../resources/image-placeholder.svg";
import { randomString, randomWords } from "../../Helpers/strings";
import { randomBetween } from "../../Helpers/numbers";

interface IProps {
    post?: IPost
}
interface IState {

}
class PostWidget extends React.Component<IProps, IState>{
    get thumbnail() {
        return this.props.post ? this.props.post.thumbnail : placeholder;
    }
    get title() {
        return this.props.post ? this.props.post.title : undefined;
    }
    get htmlTitle() {
        return {__html: this.props.post ? this.props.post.title : randomString(5, 30)};
    }
    get url() {
        const domains = ['uwcasa.nl', 'uwcasa.be', 'uwcasa.local'];
        let url = this.props.post && this.props.post.url ? this.props.post.url : '/';
        domains.forEach((domain: string) => {
            if (url.split(domain).length > 1) {
                url = url.split(domain)[1];
            }
        });
        return url;
    }
    get readableDate() {
        if (!this.props.post) {
            return randomString(10, 30);
        }
        const date = this.props.post.date;
        const d = date.getDate();
        const m = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'][date.getMonth()];
        const y = date.getFullYear();
        return d + ' ' + m + ' ' + y;
    }
    get excerpt() {
        if (this.props.post) {
            const html = {
                __html: this.props.post.excerpt
            };
            return <p className={"hide-for-small-only"} dangerouslySetInnerHTML={html} />
        }
        return <p className="hide-for-small-only"><mark>{randomWords(randomBetween(100, 200))}</mark></p>
    }
    get className() {
        const classes = ['post-widget'];
        if (!this.props.post) {
            classes.push('is-loading');
        }
        return classes.join(' ');
    }

    render() {
        return (
            <NavLink className={this.className} to={this.url}>

                <Image src={this.thumbnail} alt={this.title} />

                <h2>
                    <span dangerouslySetInnerHTML={this.htmlTitle} />
                    <br />
                    <small>{this.readableDate}</small>
                </h2>

                {this.excerpt}
            </NavLink>
        );
    }
}
export default PostWidget;
