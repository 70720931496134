import React, { useEffect, useState } from "react";
import { Menu } from "../Menu/Menu";

export function FooterMenu() {
    const [showMenu, setShowMenu] = useState();

    useEffect(() => {
        if (showMenu) {
            return;
        }
        setTimeout(() => {
            setShowMenu(true);
        }, 1000);
    }, [showMenu]);

    if (!showMenu) {
        return <></>;
    }

    return <Menu menuSlug={"footer"} />;
}