import React from 'react';
import './Topbar.scss';
import {Topmenu} from "../Topmenu/Topmenu";
import logo from '../../resources/logo.svg';
import {NavLink} from "react-router-dom";
import {MenuButton} from "../MenuButton/MenuButton";
import {MobileMenu} from "../../Containers/MobileMenu/MobileMenu";
import {ContactForm} from "../Contact/ContactForm/ContactForm";
import {Icon} from "../Icon/Icon";
import {Popup} from "../Popup/Popup";
import {Auth} from "../../Containers/Auth/Auth";
import {wp} from "../../Factories/Wordpress";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { withRouter } from 'react-router-dom'
import {Snackbar} from "../Snackbar/Snackbar";
import ReactFBLike from 'react-fb-like';

interface IProps {
    history?: any
}
interface IState {
    height: number
    marginTop: number
    mobileMenuOpen: boolean
    contactOpen: boolean
    loginOpen: boolean
    isLoading: boolean
    snackbarText?: string
}

class Topbar extends React.Component<IProps, IState> {
    desiredHeight = 50;
    startingPosition = 0;

    constructor(props: IProps = {}) {
        super(props);

        this.state = {
            height: this.desiredHeight,
            marginTop: 0,
            mobileMenuOpen: false,
            contactOpen: false,
            loginOpen: false,
            isLoading: false
        };
        // this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleContact = this.toggleContact.bind(this);
        this.openAuth = this.openAuth.bind(this);
        this.toggleAuth = this.toggleAuth.bind(this);
        this.onReset = this.onReset.bind(this);
    }
    get height() {
        return this.state.height;
    }
    get marginTop() {
        return this.state.marginTop;
    }
    get menu() {
        return <>
        <Topmenu>
            <>
                <li>
                    <button className="inline" onClick={this.toggleContact}>Contact</button>
                </li>
            </>
        </Topmenu>
        </>
    }
    componentDidMount(): void {
        setTimeout(() => {
            window.addEventListener('scroll', this.handleScroll);
        }, 50);
        // window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll);
        // window.removeEventListener('resize', this.handleResize);
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state);
    }

    handleScroll(event: Event) {
        const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;
        const direction = scrollTop < this.startingPosition ? 'up' : 'down';

        if (scrollTop < (this.height * 2) || (direction === 'up') || this.state.mobileMenuOpen) {
            this.setState({marginTop: 0});
        } else if (direction === 'down') {
            this.setState({marginTop: this.height * -1});
        }


        setTimeout(() => {
            this.startingPosition = scrollTop;
        }, 1000);
    }

    // handleResize(event: Event) {
    //     const w = window.outerWidth;
    //     if (w < 1000) {
    //         this.setState({height: 100});
    //     } else {
    //         this.setState({height: 50});
    //     }
    // }

    toggleMenu() {
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});
    }

    toggleContact() {
        this.setState({contactOpen: !this.state.contactOpen});
    }

    toggleAuth() {
        this.setState({loginOpen: !this.state.loginOpen});
    }

    openAuth() {
        wp().auth.validate().then(() => {
            this.props.history.push('/profiel')
        }).catch((error: any) => {
            this.setState({loginOpen: true});
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    onReset() {
        this.setState({loginOpen: false, snackbarText: 'Reset mail verzonden'});

        setTimeout(() => {
            this.setState({snackbarText: undefined});
        }, 2000)
    }

    render() {
        return (
            <>
                <Snackbar type={"success"}>{this.state.snackbarText}</Snackbar>
                {this.state.isLoading ? <LoadingIndicator /> : ''}
                <div className="top-bar-wrapper" style={{height: this.height}}>
                    <div className="top-bar" style={{gridTemplateRows: this.height, marginTop: this.marginTop}}>
                        <div className="hide-for-large" onClick={this.toggleMenu}>
                            <MenuButton open={this.state.mobileMenuOpen}/>
                        </div>
                        <div className={"logo-and-like"}>
                            <NavLink to={'/'}>
                                <div className="logo text-center large-text-left">
                                    <img src={logo} alt="UwCasa Logo"/>
                                </div>
                            </NavLink>
                            <ReactFBLike
                                language="nl_NL"
                                appId="1665726580354277"
                                version="v2.5"
                                layout={"button_count"}
                                share={false}
                                showFaces={false}
                                href={"https://www.facebook.com/UwCasa-594927153999460/"}
                            />
                        </div>
                        <div className="menu show-for-large">
                            {this.menu}
                        </div>
                        <div className="icon-menu">
                            <Icon type={'user'} onClick={this.openAuth}/>
                        </div>
                    </div>
                </div>
                <MobileMenu menuOpen={this.state.mobileMenuOpen} onClick={this.toggleMenu}>
                    {this.menu}
                </MobileMenu>
                <ContactForm toggle={this.toggleContact} show={this.state.contactOpen} />
                <Popup toggle={this.toggleAuth} show={this.state.loginOpen}>
                    <Auth
                        onLogin={() => (this.setState({loginOpen: false}))}
                        onReset={this.onReset}
                    />
                </Popup>

            </>
        );
    }
}

// @ts-ignore
export default withRouter(Topbar);