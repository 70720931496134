import React, { ReactElement } from 'react';
import './Input.scss';

interface IProps {
    label?: string|ReactElement,
    identifier?: string,
    required?: boolean,
    children?: any
    onChange?: any
}
interface IState {

}


class Input extends React.Component<IProps, IState> {
    inputId: string = '';

    get type() {
        return this.children[0].props.type;
    }

    get identifier() {
        if (this.inputId) {
            return this.inputId;
        }
        if (this.props.identifier) {
            return this.props.identifier;
        }
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.inputId = result;
        return result;
    }

    get children() {
        let children = this.props.children[0] ? this.props.children : [this.props.children];

        children = children.map((child: any, index: number) => {
            let identifier = this.identifier;
            if (index > 0) {
                identifier = identifier + '_' + index;
            }

            const props = {id: identifier, key: identifier} as any;
            if (this.props.required) {
                props.required = true;
            }
            if (this.props.onChange) {
                props.onChange = this.props.onChange;
            }

            return React.cloneElement(child, props);
        });

        return children;
    }
    get labels(): null|ReactElement {
        if (!this.props.label) {
            return null;
        }
        return this.children.map((child: any, index: number) => {
            let style = {};
            if (index > 0) {
                style = {
                    overflow: 'hidden',
                    height: 0,
                    width: 0
                }
            }
            return <label key={index} htmlFor={child.key} style={style}>{this.props.label}</label>;
        });
    }
    get className() {
        const classes = ['input-wrapper'];

        if (this.props.required) {
            classes.push('required');
        }

        return classes.join(' ');
    }

    render() {
        return (
            <div className={this.className}>
                {this.type === 'checkbox' ? (
                    <>
                        <div className="checkbox-wrapper">
                            {this.children}
                            <span />
                            {this.labels}
                        </div>
                    </>
                ) : (
                    <>
                        {this.labels}
                        <div className="input-container" data-length={this.children.length}>
                            {this.children}
                        </div>
                    </>
                )}

            </div>
        );
    }
}

export default Input;