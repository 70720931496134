import React from "react";
import {FooterMenu} from "../../Components/FooterMenu/FooterMenu";
import "./Footer.scss";
import FooterLocationMenu from "../../Components/FooterLocationMenu/FooterLocationMenu";

interface IProps {

}

interface IState {

}

class Footer extends React.Component<IProps, IState>{
    render() {
        return (
            <div className="footer-wrapper">
                <div className="footer-grid">
                    <FooterMenu />
                    <FooterLocationMenu />
                </div>
            </div>
        );
    }
}

export default Footer;