import React, {useEffect, useState} from "react";
import {wp} from "../../../Factories/Wordpress";
import {IProvince} from "../../../Interfaces/IProvince";
import {ICity} from "../../../Interfaces/ICity";
import {TagInput} from "../../Tag/TagInput/TagInput";
import {ILocationFilter} from "../../../Interfaces/IHouseFilter";

interface IProps {
    value?: number[]
    onChange: (v: any) => void
    parent?: number[]
    type: 'provinces'|'cities'
}

export function LocationFilter(props: IProps) {
    const [tagInput, setTagInput] = useState();

    useEffect(() => {
        const onChange = (ids: number[], names?: string[]) => {
            const selection: ILocationFilter[] = [];
            ids.forEach((id: number, index: number) => {
                selection.push({
                    id,
                    name: (names && names[index]) ? names[index] : id.toString()
                });
            });
            props.onChange(selection);
        };

        const onSearch = (searchValue?: string) => {
            const onRetrieve = (answer: any[]) => {
                return answer
                    .filter((location: IProvince | ICity) => {
                        return !searchValue || !location.name.toLowerCase().search(searchValue.toLowerCase());
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((location: IProvince | ICity) => {
                        return {id: location.term_id, name: location.name};
                    });
            };
            if (props.type === 'provinces') {
                return wp().locations.get().then((answer: IProvince[]) => {
                    return answer
                        .map((province: IProvince) => {
                            province.name = province.name
                                .split(' ')
                                .join(' ');
                            return province;
                        })
                }).then(onRetrieve);
            } else {
                return wp().cities.get().then((answer: ICity[]) => {
                    return answer.filter((city: ICity) => {
                        return !props.parent || props.parent.length === 0 || props.parent.includes(city.province.term_id);
                    });
                }).then(onRetrieve);
            }
        };
        setTagInput(<TagInput onSearch={onSearch} onChange={onChange} value={props.value}/>);
    }, [props]);

    if (!tagInput) {
        return <div/>;
    }


    return (
        <div>
            {tagInput}
        </div>
    );
}