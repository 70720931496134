import "./House.scss";
import React from "react";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import {PageImage} from "../../Components/PageImage/PageImage";
import {GridContent, LeftContent, PageContent, RightContent} from "../../Components/PageContent/PageContent";
import TagBar from "../../Containers/TagBar/TagBar";
import {PictureGrid} from "../../Components/PictureGrid/PictureGrid";
import PostBar from "../../Containers/PostBar/PostBar";
import HouseBar from "../../Containers/HouseBar/HouseBar";
import {HouseQuery} from "../../Interfaces/HouseQuery";
import {PostQuery} from "../../Interfaces/PostQuery";
import { IHouse } from "../../Interfaces/IHouse";
import { IHouseProperty } from "../../Interfaces/IHouseProperty";
import HouseTag from "../../Components/HouseTag/HouseTag";
import {LoadingIndicator} from "../../Components/LoadingIndicator/LoadingIndicator";
import {getReadablePrice} from "../../Helpers/numbers";
import {wp} from "../../Factories/Wordpress";
import {NavLink} from "react-router-dom";
import {FoldableContent} from "../../Components/FoldableContent/FoldableContent";
import {ContactForm} from "../../Components/Contact/ContactForm/ContactForm";
import {Snackbar} from "../../Components/Snackbar/Snackbar";
import {Contact} from "../../Components/Contact/Contact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PropositionForm } from "../../Components/Proposition/PropositionForm/PropositionForm";
import placeholder from "../../resources/image-placeholder.svg";


interface IProps {
    match: {params: {slug: string}},
}
interface IState {
    house?: IHouse
    showContact: boolean,
    showProposition: boolean,
    snackbarText?: string,
    isFavourite: boolean
}

export class House extends React.Component<IProps, IState> {
    private abortController = new AbortController();

    constructor(props: IProps) {
        super(props);

        this.state = {
            showContact: false,
            showProposition: false,
            isFavourite: false
        };

        this.toggleContact = this.toggleContact.bind(this);
        this.toggleProposition = this.toggleProposition.bind(this);
        this.toggleFavourite = this.toggleFavourite.bind(this);
    }

    get query() {
        const slug = this.props.match.params.slug;

        const viewedHouses = JSON.parse(localStorage.getItem('viewedHouses') || JSON.stringify([]))
            .filter((row: any) => {
                // @ts-ignore
                const timestamp = new Date(row.date);
                const currentDate = new Date();
                const diff = (currentDate.getTime() - timestamp.getTime()) / 1000;

                return diff < 3600;
            });

        const count = viewedHouses
            .filter((row: any) => {
                return row.slug === slug;
            }).length === 0;

        if (count) {
            viewedHouses.push({
                date: new Date(),
                slug
            });
        }
        localStorage.setItem('viewedHouses', JSON.stringify(viewedHouses));

        return {
            slug,
            countView: count ? 1 : 0
        } as HouseQuery
    }
    get housesQuery() {
        return {
            per_page: 4,
            filters: {
                similarTo: this.props.match.params.slug
            }
        } as HouseQuery
    }
    get postsQuery() {
        return {
            post_type: 'post',
            category_name: 'Informatie',
            limit: 4
        } as PostQuery
    }
    get properties() {
        if (!this.state.house) {
            return undefined;
        }
        return this.state.house.properties.map((property: IHouseProperty, index: number) => {
            return <HouseTag key={index} property={property} />
        });
    }
    get pageImage() {
        if (!this.state.house || !this.state.house.images || !this.state.house.images[0]) {
            return <PageImage small={true} src={placeholder} />;
        }

        return <PageImage small={true} src={this.state.house.images[0].full} />;
    }
    get images() {
        if (!this.state.house || !this.state.house.images || !this.state.house.images[0]) {
            return [];
        }
        return this.state.house.images;
    }
    get leftSide() {
        if (!this.state.house) {
            return null;
        }
        const house = this.state.house;
        const html = {__html: house.content};

        const infoLink = ((str?: string) => {
            if (!str) {
                return undefined;
            }
            return '/' + str.split('/')
                .filter((str: string, index: number) => index > 2)
                .join('/');
        })(house.additional_info);
        return (
            <React.Fragment>
                <h1>{house.title}</h1>
                <span className="price-wrapper">&euro; {getReadablePrice(house.price)}</span>
                <FoldableContent moreInfoLink={infoLink}><div dangerouslySetInnerHTML={html} /></FoldableContent>
                <div style={{fontSize: 10}}>
                    <span>Deze woning is reeds {house.views}&times; bekeken</span>
                </div>
                <Contact />
            </React.Fragment>
        );
    }
    get rightSide() {
        if (!this.state.house) {
            return null;
        }
        return (
            <React.Fragment>
                <h1 style={{margin: 0}}>&nbsp;</h1>
                <h6>Deze woning voldoet aan de volgende eigenschappen.</h6>
                <TagBar full={true}>
                    {this.properties}
                </TagBar>
            </React.Fragment>
        );
    }

    get backButton() {
        const str = 'Ga terug';
        return <button className="button secondary hollow" onClick={() => { window.scrollTo({left: 0, top: 0}); window.history.back(); }}>{str}</button>
    }

    get breadcrumbs() {
        return [
            <li>
                <NavLink to="/aanbod">Aanbod</NavLink>
            </li>,
            <li>
                <NavLink to={'/aanbod/' + this.props.match.params.slug}>{this.state.house ? this.state.house.title : 'Woning'}</NavLink>
            </li>
        ];
    }

    get icons() {
        if (!this.state.house) {
            return {};
        }
        const icons: any = {
            mail: {
                label: 'Contact',
                action: this.toggleContact
            },
            flight: {
                label: 'Planvoorstel',
                action: this.toggleProposition
            }
        };
        if (this.state.isFavourite) {
            icons.heart = {
                label: 'Favoriet verwijderen',
                action: this.toggleFavourite
            };
        } else {
            icons.heart_empty = {
                label: 'Favoriet toevoegen',
                action: this.toggleFavourite
            };
        }
        return icons;
    }

    get contents() {
        if (this.state.house) {
            return (
                <>
                    <HelmetProvider>
                        <Helmet>
                            {this.state.house.seo ? (
                                <>
                                    <title>{this.state.house.seo.title}</title>
                                    <meta name="description" content={this.state.house.seo.description} />
                                </>)
                                :
                                <></>
                            }

                        </Helmet>
                    </HelmetProvider>
                    <Snackbar type={"success"}>{this.state.snackbarText}</Snackbar>
                    <ContactForm toggle={this.toggleContact} show={this.state.showContact} context={this.state.house.id} />
                    <PropositionForm toggle={this.toggleProposition} show={this.state.showProposition} context={this.state.house.id}/>

                    <div className="house-wrapper">
                        <ScrollToTopOnMount/>
                        {this.pageImage}
                        <PageContent
                            className="regular-padding"
                            crumbs={this.breadcrumbs}
                            icons={this.icons}
                        >
                            <GridContent>
                                <LeftContent>
                                    {this.leftSide}
                                </LeftContent>
                                <RightContent>
                                    {this.rightSide}
                                </RightContent>
                            </GridContent>
                            <br />
                            <PictureGrid images={this.images} alt={this.state.house.title} />
                        </PageContent>
                        <PostBar title="Wellicht interessant." query={this.postsQuery}/>
                        <HouseBar title="Bekijk ook deze woningen." query={this.housesQuery} delayed={true}/>
                        <div className="button-wrapper">
                            {this.backButton}
                        </div>
                    </div>
                </>
            );
        }
        return <LoadingIndicator />
    }

    componentDidMount(): void {
        this.retrieve();
    }

    componentWillUnmount(): void {
        this.abortController.abort();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if ((!this.state.house && prevProps.match.params.slug.length > 0) || prevProps.match.params.slug !== this.props.match.params.slug) {
            this.retrieve();
        }
    }

    toggleContact() {
        this.setState({showContact: !this.state.showContact});
    }

    toggleProposition() {
        this.setState({showProposition: !this.state.showProposition});
    }

    public snackbarTimeout: any;
    toggleFavourite() {
        if (!this.state.house) {
            return;
        }
        const isFavourite = !this.state.isFavourite;
        wp().house.favourite(this.state.house.id, isFavourite).then((response: any) => {
            if (this.snackbarTimeout) {
                clearTimeout(this.snackbarTimeout);
            }
            this.setState({snackbarText: (isFavourite ? 'Favoriet opgeslagen!' : 'Favoriet verwijderd!'), isFavourite}, () => {
                this.snackbarTimeout = setTimeout(() => {
                    this.setState({snackbarText: undefined});
                }, 2000)
            });
        })
    }

    retrieve() {
        wp().house.get(this.query as any, this.abortController.signal, 1).then((house: IHouse) => {
            if (house) {
                this.setState({house, isFavourite: house.favourite});
                window.scrollTo(0,0);
            }
        });
    }

    render() {
        return this.contents;
    }
}