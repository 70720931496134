import React from "react";
import "./BooleanFilter.scss";

interface IProps {
    trueString?: string,
    emptyString?: string,
    falseString?: string,
    value?: boolean,
    onChange: (newValue: any) => void
}

export function BooleanFilter(props: IProps) {
    return (
        <div className="boolean-filter">
            <button
                className={props.value === true ? 'active' : ''}
                onClick={() => props.onChange(true)}
            >{props.trueString ? props.trueString : 'Ja'}</button>
            <button
                className={props.value === undefined ? 'active' : ''}
                onClick={() => props.onChange(undefined)}
            >{props.emptyString ? props.emptyString : 'Geen voorkeur'}</button>
            <button
                className={props.value === false ? 'active' : ''}
                onClick={() => props.onChange(false)}
            >{props.falseString ? props.falseString : 'Nee'}</button>
        </div>
    );
}