import React, {useState} from "react";
import {LoadingIndicator} from "../../../Components/LoadingIndicator/LoadingIndicator";
import {Error} from "../../../Components/Error/Error";
import Input from "../../../Components/Input/Input";
import {wp} from "../../../Factories/Wordpress";

interface IProps {
    onSubmit: () => void
}

export function Forgot(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [email, setEmail] = useState();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');


        wp().auth2.forgot(email).then(() => {
            setIsLoading(false);
            props.onSubmit();
        }).catch((error: any) => {
            setError('Gegevens onbekend');
            setIsLoading(false);
        });
    };

    return (
        <div className="forgot-wrapper">
            <h1>Wachtwoord vergeten.</h1>
            {isLoading ? <LoadingIndicator contained={false} /> : ''}
            {error ? <Error>{error}</Error> : ''}

            <form onSubmit={handleSubmit}>
                <Input label="Uw email" required={true}>
                    <input type="email" placeholder="hendrik@huis.nl" onChange={(event) => setEmail(event.target.value)}/>
                </Input>
                <div className="text-center">
                    <button className="button secondary" type="submit">Verzenden</button>
                </div>
            </form>
        </div>
    );
}