import React from "react";
import './PictureGrid.scss';
import Image from "../Image/Image";
import {Lightbox} from "../Lightbox/Lightbox";
import {IImage} from "../../Interfaces/IImage";

interface IProps {
    images: any
    alt?: string
}
interface IState {
    showLightbox: boolean,
    startLightboxAt?: number,
}

export class PictureGrid extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {showLightbox: false};

        this.closeLightbox = this.closeLightbox.bind(this);
    }
    get pictures() {
        return this.props.images
            .map((image: IImage, index: number) => {
                const url = image.full;
                return (
                    <Image key={index} thumbnail={image.thumbnail} src={url} alt={this.props.alt + ' ' + (index + 1)} onClick={() => {this.openImage(index)}} />
                );
            })
    }

    get lightbox() {
        const show = this.state.showLightbox && this.state.startLightboxAt !== undefined;
        const images = this.props.images.map((image: IImage) => image.full);
        return <Lightbox show={show} images={images} startAt={this.state.startLightboxAt ? this.state.startLightboxAt : 0} onClose={this.closeLightbox} />;
    }

    closeLightbox() {
        this.setState({showLightbox: false});
    }

    openImage(index: number) {
        this.setState({
            showLightbox: true,
            startLightboxAt: index
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.lightbox}
                <div className="picture-grid">
                    {this.pictures}
                </div>
            </React.Fragment>
        );
    }
}