import React, {ReactNode, useEffect, useState} from 'react';
import {Route, withRouter} from 'react-router-dom'
import { PageContent} from "../../Components/PageContent/PageContent";
import { PageImage } from "../../Components/PageImage/PageImage";
import { NavLink } from "react-router-dom";
import "./Profile.scss";
import {EditProfile} from "./EditProfile/EditProfile";
import {Favourites} from "./Favourites/Favourites";
import {ITokenSuccessResponse, ITokenValidationResponse} from "squde-wp-api/ITokenResponses";
import {wp} from "../../Factories/Wordpress";
import {LoadingIndicator} from "../../Components/LoadingIndicator/LoadingIndicator";
import {EditPassword} from "./EditPassword/EditPassword";

interface IProps {
    children?: ReactNode|ReactNode[],
    match?: any,
    history?: any
}

function Profile(props: IProps) {
    const [userDetails, setUserDetails] = useState<ITokenSuccessResponse|undefined>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isLoading && !userDetails) {
            setIsLoading(true);
            wp().auth.validate().then((data: ITokenValidationResponse) => {
                setIsLoading(false);
                setUserDetails(data.details);
            }).catch(() => {
                props.history.push('/');
            })
        }
    }, [isLoading, userDetails, props.history]);

    const logOut = () => {
        wp().auth.logout().then(() => {
            props.history.push('/')
        });
    };

    return (
        <>
            {isLoading ? <LoadingIndicator /> : ''}
            <PageImage noBlur={true} src={'auto'} small={true} />
            <PageContent crumbs={false}>
                <div className="profile-contents-wrapper">
                    <div className="profile-menu-wrapper">
                        <h1 className="show-for-medium">&nbsp;</h1>
                        <ul>
                            <li>
                                <NavLink to="/profiel" exact={true} activeClassName={"active"}>Gegevens</NavLink>
                            </li>
                            <li>
                                <NavLink to="/profiel/wachtwoord" exact={true} activeClassName={"active"}>Wachtwoord</NavLink>
                            </li>
                            <li>
                                <NavLink to="/profiel/favorieten" activeClassName={"active"}>Favorieten</NavLink>
                            </li>
                            <li>
                                <button onClick={logOut}>Uitloggen</button>
                            </li>
                        </ul>
                    </div>
                    <div className="profile-contents">
                        {userDetails ? (
                            <>
                                <Route exact path="/profiel" render={(props) => <EditProfile {...{}} userDetails={userDetails} />}/>
                                <Route exact path="/profiel/wachtwoord" render={(props) => <EditPassword {...{}} userDetails={userDetails} />}/>
                                <Route exact path="/profiel/favorieten" component={Favourites}/>
                            </>
                        ) : ''}
                    </div>
                </div>
            </PageContent>
        </>
    );
}

// @ts-ignore
export default withRouter(Profile);