import "./Snackbar.scss";
import React, {useEffect, useState} from "react";

interface IProps {
    children?: string
    type: "success"|"error"
}

let timeout: any;

export function Snackbar(props: IProps) {
    const [text, setText] = useState<string|undefined>();

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setText(props.children);
        }, (props.children ? 0 : 500));
    }, [props.children]);

    const classes = ['snackbar', props.type];

    if (!!props.children) {
        classes.push('show');
    }

    return (
        <div className={classes.join(' ')}>
            {text}
        </div>
    );
}