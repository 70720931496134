import React from 'react';
import { IMenu } from "../../Interfaces/IMenu";
import { NavLink } from "react-router-dom";
import { getBasePath } from "../../Helpers/Routing";
import { wp } from "../../Factories/Wordpress";

interface IProps {
    menuSlug: string
}
interface IState {
    isLoading: boolean,
    menu?: IMenu[],
}

export class Menu extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {isLoading: true};
    }

    componentDidMount(): void {
        wp().menus.get({menu: this.props.menuSlug}).then((menu: IMenu[]) => {
            this.setState({
                isLoading: false,
                menu
            });
        })
    }

    hasPrefix(url: string) {
        const basename = url.split('/')[url.split('/').length - 1];
        return basename.split('.').length > 1;
    }

    get menu() {
        if (!this.state.menu) {
            return null;
        }
        return this.state.menu.map((item: IMenu) => {
            let url = getBasePath(item.url);
            if (url === '#') {
                url = '/';
            }
            let link;
            if (!this.hasPrefix(url)) {
                link = <NavLink exact activeClassName="active" to={url}>{item.title}</NavLink>;
            } else {
                link = <a href={url} target="_blank" rel="noopener noreferrer">{item.title}</a>;
            }

            return <li key={item.ID}>{link}</li>;
        });
    }

    render() {
        return (
            <ul>
                {this.menu}
            </ul>
        );
    }
}