import React from "react";
import {PageImage} from "../../Components/PageImage/PageImage";
import { PageContent } from "../../Components/PageContent/PageContent";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import PostBar from "../../Containers/PostBar/PostBar";
import {PostQuery} from "../../Interfaces/PostQuery";
import HouseBar from "../../Containers/HouseBar/HouseBar";
import {HouseQuery} from "../../Interfaces/HouseQuery";
import { LoadingIndicator } from "../../Components/LoadingIndicator/LoadingIndicator";
import { IPost } from "../../Interfaces/IPost";
import {wp} from "../../Factories/Wordpress";
import {NavLink} from "react-router-dom";
import placeholder from "../../resources/image-placeholder.svg";
import parse from 'html-react-parser';

interface IProps {
    match: {params: {slug: string}}
    parent: false
}
interface IState {
    post?: IPost
}

export class Post extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }
    get query() {
        return {
            slug: this.props.match.params.slug
        } as HouseQuery
    }
    get housesQuery() {
        return {
            meta: [{
                key: 'zw_on_frontpage',
                value: 1
            }],
            per_page: 4
        } as HouseQuery
    }
    get postsQuery() {
        return {
            post_type: 'post',
            category_name: 'Informatie',
            limit: 4
        } as PostQuery
    }

    get title () {
        if (this.state.post) {
            return this.state.post.title;
        }
        return undefined;
    }
    get content () {
        if (this.state.post) {
            return this.state.post.content;
        }
        return undefined;
    }


    get breadcrumbs() {
        if (!this.state.post) {
            return null;
        }

        const crumbs = [];

        crumbs.push(
            <li>
                <NavLink to="/informatie">Informatie</NavLink>
            </li>);

        crumbs.push(
            <li>
                <NavLink to={'/informatie/' + this.props.match.params.slug}>{parse(this.state.post.title)}</NavLink>
            </li>);

        return crumbs;
    }

    get image() {
        if (this.state.post && this.state.post.thumbnail) {
            return this.state.post.thumbnail;
        }
        return placeholder;
    }

    get contents() {
        if (this.state.post) {
            return (
                <div className="house-wrapper">
                    <ScrollToTopOnMount/>
                    <PageImage small={true} src={this.image} />
                    <PageContent className="regular-padding" crumbs={this.breadcrumbs} title={this.title}>
                        {this.content}
                    </PageContent>
                    <PostBar title="Wellicht interessant." query={this.postsQuery}/>
                    <HouseBar title="Bekijk ook deze woningen." query={this.housesQuery} delayed={true}/>
                </div>
            );
        }
        return <LoadingIndicator />
    }

    componentDidMount(): void {
        this.retrieve();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if ((!this.state.post && prevProps.match.params.slug.length > 0) || prevProps.match.params.slug !== this.props.match.params.slug) {
            this.retrieve();
        }
    }

    retrieve() {
        wp().singlePostByQuery.get(this.query as {}).then((post: IPost) => {
            this.setState({post});
            window.scrollTo(0,0);
        });
    }

    render() {
        return (
            <div className="post-wrapper">
                <ScrollToTopOnMount/>
                {this.contents}
            </div>
        );
    }
}
