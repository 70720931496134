import React from "react";
import './ArrowButton.scss';

interface IProps {
    direction: string,
    onClick: () => void,
    show?: boolean,
}
interface IState {
    marginLeft: number
    marginTop: number
}

class ArrowButton extends React.Component<IProps, IState> {
    private mutationObserver?: MutationObserver;
    protected ref: HTMLDivElement|null = null;

    constructor(props: IProps) {
        super(props);

        this.state = {
            marginLeft: 0,
            marginTop: 0,
        };
        this.setPosition = this.setPosition.bind(this);
    }

    get className() {
        const classes = ['arrow-button-wrapper'];
        classes.push(this.props.direction);

        if (this.shouldShow) {
            classes.push('show');
        }

        return classes.join(' ');
    }

    get shouldShow() {
        if (this.props.show === undefined) {
            return true;
        }
        return this.props.show;
    }

    get elmParent() {
        if (!this.ref || !this.ref.parentElement) {
            return new HTMLDivElement();
        }
        return this.ref.parentElement;
    }

    get prev() {
        if (!this.ref) {
            return new HTMLDivElement();
        }
        let prev = this.ref.previousElementSibling;
        if (!prev) {
            return new HTMLDivElement();
        }
        if (prev.classList.contains('arrow-button-wrapper')) {
            prev = prev.previousElementSibling;
        }

        if (!this.mutationObserver && prev) {
            const mutationId = btoa((Math.floor(Math.random() * 20000) + 1).toString()).split('=').join('');
            prev.setAttribute('data-mutation', mutationId);
            this.mutationObserver = new MutationObserver((mutations => {
                this.setPosition();
            }));
            this.mutationObserver.observe(document.querySelectorAll('[data-mutation="' + mutationId + '"]')[0], {
                attributes: true,
                characterData: true,
                childList: false,
                subtree: false,
                attributeOldValue: true,
                characterDataOldValue: true
            });
        }

        return prev;
    }

    componentDidMount(): void {
        this.setPosition();
        window.addEventListener('resize', this.setPosition);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.setPosition);

        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    setPosition() {
        if (!this.ref) {
            return;
        }
        const prev = this.prev as HTMLElement;

        if (!prev) {
            return;
        }

        const prevWidth = prev.offsetWidth;
        const prevHeight = prev.offsetHeight;

        const marginLeft = this.props.direction === 'right' ? (prevWidth - 4) : ((this.props.direction === 'left' ? 0 : prevWidth / 2) + 4);
        let marginTop = this.props.direction === 'up' ? prevHeight * -1 : (this.props.direction === 'down' ? 0 : prevHeight / 2 * -1);

        const imageSelector = prev.querySelectorAll('.image');
        if (imageSelector.length > 0 && (this.props.direction === 'right' || this.props.direction === 'left')) {
            const firstImage = imageSelector[0] as HTMLElement;
            const imageHeight = firstImage.offsetHeight;
            marginTop = prevHeight * -1 + imageHeight / 2;
        }

        this.setState({marginLeft, marginTop});

    }

    render() {
        return (
            <div
                className={this.className}
                style={{marginLeft: this.state.marginLeft, marginTop: this.state.marginTop}}
                onClick={this.props.onClick}
                ref={(elm: HTMLDivElement) => this.ref = elm}
            >
                <div className="arrow-button" />
            </div>
        );
    }
}
export default ArrowButton;