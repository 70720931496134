import React from "react";
import {PageImage} from "../../Components/PageImage/PageImage";
import {PageContent} from "../../Components/PageContent/PageContent";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import PostBar from "../../Containers/PostBar/PostBar";
import {PostQuery} from "../../Interfaces/PostQuery";
import DangerouslySetHtmlContent from 'dangerously-set-html-content'
import {ICity} from "../../Interfaces/ICity";
import {IProvince} from "../../Interfaces/IProvince";
import {wp} from "../../Factories/Wordpress";
import {NavLink} from "react-router-dom";
import { Houses } from "../Houses/Houses";

interface IProps {
    match: {path: string},
}
interface IState {
    city?: ICity
}

export class City extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.retrieve();
    }

    retrieve() {
        wp().locations.get().then((provinces: IProvince[]) => {
            provinces = provinces.filter((province: IProvince) => {
                return province.cities.filter((city: ICity) => {
                    return city.slug === this.slug;
                }).length > 0;
            });
            const city = provinces[0].cities.filter((city: ICity) => {
                return city.slug === this.slug;
            })[0];

            this.setState({city});

            window.scrollTo(0,0);
        });
    }

    get slug() {
        const path = this.props.match.path.split('/');
        return path[path.length - 1];
    }

    get breadcrumbs() {
        if (!this.state.city) {
            return null;
        }
        return [
            <li>
                <NavLink to={'/aanbod/' + this.state.city.province.slug}>{this.state.city.province.name}</NavLink>
            </li>,
            <li>
                <NavLink to={'/aanbod/' + this.state.city.province.slug + '/' + this.slug}>{this.state.city.name}</NavLink>
            </li>
        ];
    }

    get contents() {
        if (this.state.city) {
            const city = this.state.city;

            return (
                <React.Fragment>
                    <PageImage src={city.image.full} />
                    <PageContent title={city.name} withContactButton={true} crumbs={this.breadcrumbs}>
                        <DangerouslySetHtmlContent html={city.description} />
                        <div className="inline-houses-wrapper">
                            <Houses inline={true} city={city.term_id} />
                        </div>
                    </PageContent>
                </React.Fragment>
            );
        }
        return null;
    }

    get postsQuery() {
        return {
            post_type: 'post',
            category_name: 'Informatie',
            limit: 4
        } as PostQuery
    }

    render() {
        return (
            <div className="post-wrapper">
                <ScrollToTopOnMount/>
                {this.contents}
                <PostBar title="Wellicht interessant." query={this.postsQuery}/>
            </div>
        );
    }
}