import React from "react";
import "./HouseSortings.scss";
import {Select} from "../Select/Select";

interface IProps {
    onChange: any,
    value?: string|null
    inline?: boolean
}
interface IState {

}

export class HouseSortings extends React.Component<IProps, IState>{
    get classes() {
        const classes = ['house-sortings-wrapper'];
        if (this.props.inline) {
            classes.push('inline-sortings');
        }
        return classes.join(' ');
    }
    render() {
        return (
            <div className={this.classes}>
                <h1>Sorteren op</h1>
                <Select onChange={this.props.onChange} value={this.props.value}>
                    <option value="date_new">Nieuw</option>
                    <option value="price_low">Prijs laag, hoog</option>
                    <option value="price_high">Prijs hoog, laag</option>
                </Select>
            </div>
        );
    }
}