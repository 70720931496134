import React from "react";
import { IProvince } from "../../Interfaces/IProvince";
import { ICity } from "../../Interfaces/ICity";
import './LocationWidget.scss';
import Image from "../Image/Image";
import {getReadablePrice} from "../../Helpers/numbers";
import {NavLink} from "react-router-dom";
import placeholder from "../../resources/image-placeholder.svg";

interface IProps {
    location?: (ICity | IProvince)
}
interface IState {
}

class LocationWidget extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    get isCity () {
        return (object: any): object is ICity => {
            return 'province' in object;
        };
    }

    get url() {
        let url = '/aanbod/';
        if (!this.props.location) {
            return url;
        }
        if (this.isCity) {
            const city = this.props.location as ICity;
            url += city.province.slug + '/';
        }
        url += this.props.location.slug;
        return url;
    }

    get image() {
        if (!this.props.location || !this.props.location.image.full) {
            return placeholder;
        }
        return this.props.location.image.full;
    }

    get thumbnail() {
        if (!this.props.location) {
            return placeholder;
        }
        return this.props.location.image.thumbnail;
    }

    get className() {
        const classes = ['location-widget-wrapper'];

        if (!this.props.location) {
            classes.push('is-loading');
        }

        return classes.join(' ');
    }


    render() {
        return (
            <NavLink to={this.url} className={this.className}>
                <Image src={this.image} thumbnail={this.thumbnail} />
                {this.props.location ? (
                    <div className="title-wrapper">
                        <h2>{this.props.location.name}<br />
                            <small>Woningen vanaf {getReadablePrice(this.props.location.minimal_house_price)}</small>
                        </h2>
                    </div>
                ) : <></>}
            </NavLink>
        );
    }
}

export default LocationWidget;