import React, {useState} from "react";
import "./SearchSelect.scss";

interface IProps {
    placeholder?: string,
    onChange?: (e: any) => void
    name?: string,
    search: (search: string) => Promise<any>
}

interface ISelectOption {
    id: number,
    name: string
}

export function SearchSelect(props: IProps) {
    let input: null|HTMLInputElement;

    const [value, setValue] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [showList, setShowList] = useState(false);

    const onFocus = () => {
        search('');
    };

    const onSearch = (e: any) => {
        search(e.target.value);
    };

    const search = (v: string) => {
        setValue(v);
        props.search(v).then((result: ISelectOption[]) => {
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            setOptions(result);
            setShowList(result.length > 0);
        })
    };

    const searchKeys = (e: any) => {
        if (e.key === 'Tab' || e.key === 'Enter') {
            if (options.length > 0) {
                onSelectOption(options[0]);
            }
        }
    };

    const onSelectOption = (option: ISelectOption) => {
        setValue(option.name);
        setId(option.id.toString());
        if (input) {
            // @ts-ignore
            const setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
            // @ts-ignore
            setValue.call(input, option.id);

            const e = new Event('input', { bubbles: true });
            input.dispatchEvent(e);
        }
    };

    return (
        <>
            <div className={"search-select-wrapper"}>
                <input autoComplete={"off"} type="text" value={value} placeholder={props.placeholder} onKeyDown={searchKeys} onChange={onSearch} onFocus={onFocus} onBlur={() => setTimeout(() => setShowList(false), 250)}/>
                <ul className={"options-wrapper " + (showList ? 'show' : '')}>
                    {options.map((option: ISelectOption) => {
                        return <li key={option.id} onClick={() => onSelectOption(option)}>{option.name}</li>;
                    })}
                </ul>
                <input ref={(elm) => input = elm} style={{display: 'none'}} type="text" name={props.name} value={id} onChange={props.onChange}/>
            </div>
        </>
    );
}