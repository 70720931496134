import React from "react";
import './Lightbox.scss';

interface IProps {
    images: string[],
    startAt: number,
    onClose: any,
    show: boolean,
}
interface IState {
    activeIndex: number
    showThumbs: boolean
    isDragging: boolean
    dragX: number
    startPosition: number
    stopPosition: number
}

export class Lightbox extends React.Component<IProps, IState> {
    private element: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeIndex: props.startAt,
            showThumbs: false,
            isDragging: false,
            dragX: 0,
            startPosition: 0,
            stopPosition: 0
        };

        this.movePicture = this.movePicture.bind(this);
        this.setIndex = this.setIndex.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.goLeft = this.goLeft.bind(this);
        this.goRight = this.goRight.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);


        this.startDragging = this.startDragging.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.stopDragging = this.stopDragging.bind(this);
        this.preventVerticalScroll = this.preventVerticalScroll.bind(this);
    }
    get images() {
        return this.props.images.map((url: string, index: number) => {
            return (
                <li key={index}>
                    <div className="lightbox-image" style={{backgroundImage: 'url(' + url + ')'}} />
                </li>
            );
        })
    }
    get thumbnails() {
        return this.props.images.map((url: string, index: number) => {
            return (
                <li key={index}>
                    <div className="lightbox-image" style={{backgroundImage: 'url(' + url + ')'}} onClick={() => {this.setIndex(index)}} />
                </li>
            );
        })
    }
    get className() {
        const classes = ['lightbox-wrapper'];
        if (this.props.show) {
            classes.push('show');
        }
        if (this.state.isDragging) {
            classes.push('is-dragging');
        }
        return classes.join(' ');
    }

    setIndex(index: number) {
        this.setState({showThumbs: false, activeIndex: index});
    }

    componentDidMount(): void {
        window.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('touchend', this.stopDragging);
        window.addEventListener('touchmove', this.preventVerticalScroll, {passive: false});
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.startAt !== this.props.startAt && this.props.startAt !== this.state.activeIndex) {
            this.setIndex(this.props.startAt);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('touchend', this.stopDragging);
        window.removeEventListener('touchmove', this.preventVerticalScroll);
    }

    onKeyDown(event: any) {
        if (event.code === 'ArrowRight') {
            this.movePicture('right')

        }
        if (event.code === 'ArrowLeft') {
            this.movePicture('left')
        }
    }

    movePicture(direction: string) {
        let index = this.state.activeIndex;
        if (direction === 'right') {
            if (index < this.images.length - 1) {
                index++;
            }
        } else {
            if (index > 0) {
                index--;
            }
        }

        this.setState({activeIndex: index, isDragging: false});
    }

    goLeft() {
        this.movePicture('left');
    }
    goRight() {
        this.movePicture('right');
    }

    closeLightbox() {
        this.props.onClose();
    }

    preventVerticalScroll (e: any) {
        if(this.props.show) { e.preventDefault(); e.returnValue = false; return false; }
    }

    startDragging(event: any) {
        if (event.target.classList.contains('button')) {
            return;
        }
        if (!this.state.isDragging) {
            this.setState({
                isDragging: true,
                startPosition: event.touches[0].clientX,
                stopPosition: event.touches[0].clientX,
                dragX: 0
            });
        }
    }
    handleDrag(event: any) {
        if (this.state.isDragging) {
            const current = event.touches[0].clientX;
            const dragX = current - this.state.startPosition;
            this.setState({dragX, stopPosition: current});
        }
    }
    stopDragging() {
        if (this.state.isDragging) {
            const start = this.state.startPosition;
            const end = this.state.stopPosition;
            const direction = start > end ? 'right' : 'left';
            const minimumDiff = window.outerWidth / 100 * 20;
            const diff = (start - end) < 0 ? (start - end) * -1 : (start - end);

            if (diff > minimumDiff) {
                if (direction === 'right') {
                    this.goRight();
                } else {
                    this.goLeft();
                }
            } else {
                const newState: any = {isDragging: false};

                this.setState(newState);
            }
        }
    }


    get dragStyle () {
        if (!this.state.isDragging) {
            return {};
        }
        return {transform: 'translateX(calc((' + this.state.activeIndex + ' * -100vw) + ' + this.state.dragX + 'px))'};
    }

    render() {
        return (
            <div className={this.className}
                 onTouchStart={this.startDragging}
                 onTouchMove={this.handleDrag}
            >
                <div className="close-button" onClick={this.closeLightbox} />
                <div className="left-invisible-button invisible-button" onClick={this.goLeft} />
                <div className="right-invisible-button invisible-button" onClick={this.goRight} />
                <div className={"image-container"}>
                    <ul
                        className="big-wrapper"
                        data-active={this.state.activeIndex}
                        data-contains={this.images.length}
                        style={this.dragStyle}>
                        {this.images}
                    </ul>
                    <div className="position-wrapper">
                        {(this.state.activeIndex + 1)} / {this.images.length}
                    </div>
                </div>
                <button className="thumbs-button" onClick={() => this.setState({showThumbs: !this.state.showThumbs})}><span className="icon-th-large" />Alle foto's</button>
                <div className={"thumbnails-container " + (this.state.showThumbs ? 'show' : '')}>
                    <h4>{this.images.length} foto's</h4>
                    <ul className="thumbnail-wrapper" data-active={this.state.activeIndex} data-contains={this.images.length}>
                        {this.thumbnails}
                    </ul>
                </div>
            </div>
        );
    }
}