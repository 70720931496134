import React, {useState} from "react";
import {ITokenSuccessResponse} from "squde-wp-api/ITokenResponses";
import Input from "../../../Components/Input/Input";
import {wp} from "../../../Factories/Wordpress";
import {LoadingIndicator} from "../../../Components/LoadingIndicator/LoadingIndicator";
import {IUserUpdateRequest, IUserUpdateResponse} from "squde-wp-api/IUser";

interface IProps {
    userDetails: ITokenSuccessResponse
}

export function EditProfile(props: IProps) {
    const [isLoading, setIsLoading] = useState();
    const [name, setName] = useState(props.userDetails.user_display_name);
    const [email, setEmail] = useState(props.userDetails.user_email);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        wp().user.update({name, email} as IUserUpdateRequest).then((userData: IUserUpdateResponse) => {
            setName(userData.name);
            setEmail(userData.email);
            setIsLoading(false);
        }).catch((e: any) => {
            setIsLoading(false);
            console.error(e);
        });
    };


    return (
        <>
            {isLoading ? <LoadingIndicator /> : ''}
            <h1>Het profiel van {props.userDetails.user_display_name}.</h1>
            <form onSubmit={handleSubmit}>
                <Input label="Uw naam" required={true}>
                    <input type="text" placeholder={name} value={name} onChange={(event) => setName(event.target.value)}/>
                </Input>
                <Input label="Uw email" required={true}>
                    <input type="email" placeholder={email} value={email} onChange={(event) => setEmail(event.target.value)}/>
                </Input>
                <div className="text-center">
                    <button type="submit" className="button secondary">Opslaan</button>
                </div>
            </form>
        </>
    );
}