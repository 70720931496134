import React from "react";
import './LoadingIndicator.scss';

interface IProps {
    contained?: boolean
    inline?: boolean
}
interface IState {

}

export class LoadingIndicator extends React.Component<IProps, IState>{
    get className() {
        const classes = ["loading-wrapper"];

        if (this.props.contained) {
            classes.push('contained');
        }
        if (this.props.inline) {
            classes.push('inline');
        }

        return classes.join(' ');
    }
    render() {
        return (
            <div className={this.className}>
                <div className="ball primary" />
                <div className="ball secondary" />
            </div>
        );
    }
}