import React from "react";
import './PageContent.scss';
import {Breadcrumbs} from "../Breadcrumbs/Breadcrumbs";
import { Contact } from "../Contact/Contact";
import parse, { domToReact } from 'html-react-parser';
import { PropositionForm } from "../Proposition/PropositionForm/PropositionForm";
import { ContentLink } from "./ContentLink/ContentLink";

export interface IPageIconButton {
    action: () => void,
    label?: string
}

interface IProps {
    title?: string
    children: any
    withContactButton?: boolean
    className?: string
    crumbs?: any
    icons?: {
        glass? : IPageIconButton
        music? : IPageIconButton
        search? : IPageIconButton
        search_circled? : IPageIconButton
        mail? : IPageIconButton
        mail_circled? : IPageIconButton
        heart? : IPageIconButton
        heart_circled? : IPageIconButton
        heart_empty? : IPageIconButton
        star? : IPageIconButton
        star_circled? : IPageIconButton
        star_empty? : IPageIconButton
        user? : IPageIconButton
        group? : IPageIconButton
        group_circled? : IPageIconButton
        torso? : IPageIconButton
        video? : IPageIconButton
        video_circled? : IPageIconButton
        video_alt? : IPageIconButton
        videocam? : IPageIconButton
        video_chat? : IPageIconButton
        picture? : IPageIconButton
        camera? : IPageIconButton
        photo? : IPageIconButton
        photo_circled? : IPageIconButton
        th_large? : IPageIconButton
        th? : IPageIconButton
        th_list? : IPageIconButton
        view_mode? : IPageIconButton
        ok? : IPageIconButton
        ok_circled? : IPageIconButton
        ok_circled2? : IPageIconButton
        cancel? : IPageIconButton
        cancel_circled? : IPageIconButton
        cancel_circled2? : IPageIconButton
        plus? : IPageIconButton
        plus_circled? : IPageIconButton
        minus? : IPageIconButton
        minus_circled? : IPageIconButton
        help? : IPageIconButton
        help_circled? : IPageIconButton
        info_circled? : IPageIconButton
        home? : IPageIconButton
        home_circled? : IPageIconButton
        website? : IPageIconButton
        website_circled? : IPageIconButton
        attach? : IPageIconButton
        attach_circled? : IPageIconButton
        lock? : IPageIconButton
        lock_circled? : IPageIconButton
        lock_open? : IPageIconButton
        lock_open_alt? : IPageIconButton
        eye? : IPageIconButton
        eye_off? : IPageIconButton
        tag? : IPageIconButton
        tags? : IPageIconButton
        bookmark? : IPageIconButton
        bookmark_empty? : IPageIconButton
        flag? : IPageIconButton
        flag_circled? : IPageIconButton
        thumbs_up? : IPageIconButton
        thumbs_down? : IPageIconButton
        download? : IPageIconButton
        download_alt? : IPageIconButton
        upload? : IPageIconButton
        share? : IPageIconButton
        quote? : IPageIconButton
        quote_circled? : IPageIconButton
        export? : IPageIconButton
        pencil? : IPageIconButton
        pencil_circled? : IPageIconButton
        edit? : IPageIconButton
        edit_circled? : IPageIconButton
        edit_alt? : IPageIconButton
        print? : IPageIconButton
        retweet? : IPageIconButton
        comment? : IPageIconButton
        comment_alt? : IPageIconButton
        bell? : IPageIconButton
        warning? : IPageIconButton
        exclamation? : IPageIconButton
        error? : IPageIconButton
        error_alt? : IPageIconButton
        location? : IPageIconButton
        location_circled? : IPageIconButton
        compass? : IPageIconButton
        compass_circled? : IPageIconButton
        trash? : IPageIconButton
        trash_circled? : IPageIconButton
        doc? : IPageIconButton
        doc_circled? : IPageIconButton
        doc_new? : IPageIconButton
        doc_new_circled? : IPageIconButton
        folder? : IPageIconButton
        folder_circled? : IPageIconButton
        folder_close? : IPageIconButton
        folder_open? : IPageIconButton
        rss? : IPageIconButton
        phone? : IPageIconButton
        phone_circled? : IPageIconButton
        cog? : IPageIconButton
        cog_circled? : IPageIconButton
        cogs? : IPageIconButton
        wrench? : IPageIconButton
        wrench_circled? : IPageIconButton
        basket? : IPageIconButton
        basket_circled? : IPageIconButton
        calendar? : IPageIconButton
        calendar_circled? : IPageIconButton
        mic? : IPageIconButton
        mic_circled? : IPageIconButton
        volume_off? : IPageIconButton
        volume_down? : IPageIconButton
        volume? : IPageIconButton
        volume_up? : IPageIconButton
        headphones? : IPageIconButton
        clock? : IPageIconButton
        clock_circled? : IPageIconButton
        lightbulb? : IPageIconButton
        lightbulb_alt? : IPageIconButton
        block? : IPageIconButton
        resize_full? : IPageIconButton
        resize_full_alt? : IPageIconButton
        resize_small? : IPageIconButton
        resize_vertical? : IPageIconButton
        resize_horizontal? : IPageIconButton
        move? : IPageIconButton
        zoom_in? : IPageIconButton
        zoom_out? : IPageIconButton
        down_open? : IPageIconButton
        left_open? : IPageIconButton
        right_open? : IPageIconButton
        up_open? : IPageIconButton
        down? : IPageIconButton
        left? : IPageIconButton
        right? : IPageIconButton
        up? : IPageIconButton
        down_circled? : IPageIconButton
        left_circled? : IPageIconButton
        right_circled? : IPageIconButton
        up_circled? : IPageIconButton
        down_hand? : IPageIconButton
        left_hand? : IPageIconButton
        right_hand? : IPageIconButton
        up_hand? : IPageIconButton
        cw? : IPageIconButton
        cw_circled? : IPageIconButton
        arrows_cw? : IPageIconButton
        shuffle? : IPageIconButton
        play? : IPageIconButton
        play_circled? : IPageIconButton
        play_circled2? : IPageIconButton
        stop? : IPageIconButton
        stop_circled? : IPageIconButton
        pause? : IPageIconButton
        pause_circled? : IPageIconButton
        record? : IPageIconButton
        eject? : IPageIconButton
        backward? : IPageIconButton
        backward_circled? : IPageIconButton
        fast_backward? : IPageIconButton
        fast_forward? : IPageIconButton
        forward? : IPageIconButton
        forward_circled? : IPageIconButton
        step_backward? : IPageIconButton
        step_forward? : IPageIconButton
        target? : IPageIconButton
        signal? : IPageIconButton
        desktop? : IPageIconButton
        desktop_circled? : IPageIconButton
        laptop? : IPageIconButton
        laptop_circled? : IPageIconButton
        network? : IPageIconButton
        inbox? : IPageIconButton
        inbox_circled? : IPageIconButton
        inbox_alt? : IPageIconButton
        globe? : IPageIconButton
        globe_alt? : IPageIconButton
        cloud? : IPageIconButton
        cloud_circled? : IPageIconButton
        flight? : IPageIconButton
        leaf? : IPageIconButton
        font? : IPageIconButton
        fontsize? : IPageIconButton
        bold? : IPageIconButton
        italic? : IPageIconButton
        text_height? : IPageIconButton
        text_width? : IPageIconButton
        align_left? : IPageIconButton
        align_center? : IPageIconButton
        align_right? : IPageIconButton
        align_justify? : IPageIconButton
        list? : IPageIconButton
        indent_left? : IPageIconButton
        indent_right? : IPageIconButton
        briefcase? : IPageIconButton
        off? : IPageIconButton
        road? : IPageIconButton
        qrcode? : IPageIconButton
        barcode? : IPageIconButton
        braille? : IPageIconButton
        book? : IPageIconButton
        adjust? : IPageIconButton
        tint? : IPageIconButton
        check? : IPageIconButton
        check_empty? : IPageIconButton
        asterisk? : IPageIconButton
        gift? : IPageIconButton
        fire? : IPageIconButton
        magnet? : IPageIconButton
        chart? : IPageIconButton
        chart_circled? : IPageIconButton
        credit_card? : IPageIconButton
        megaphone? : IPageIconButton
        clipboard? : IPageIconButton
        hdd? : IPageIconButton
        key? : IPageIconButton
        certificate? : IPageIconButton
        tasks? : IPageIconButton
        filter? : IPageIconButton
        gauge? : IPageIconButton
        smiley? : IPageIconButton
        smiley_circled? : IPageIconButton
        address_book? : IPageIconButton
        address_book_alt? : IPageIconButton
        asl? : IPageIconButton
        glasses? : IPageIconButton
        hearing_impaired? : IPageIconButton
        iphone_home? : IPageIconButton
        person? : IPageIconButton
        adult? : IPageIconButton
        child? : IPageIconButton
        blind? : IPageIconButton
        guidedog? : IPageIconButton
        accessibility? : IPageIconButton
        universal_access? : IPageIconButton
        male? : IPageIconButton
        female? : IPageIconButton
    }
}
interface IState {
    showPropositionForm: boolean
}

export class PageContent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {showPropositionForm: false};
    }
    get className(): string {
        const classes = ['page-content-wrapper'];

        if (this.props.className) {
            classes.push(this.props.className);
        }

        return classes.join(' ');
    }
    get content() {
        const options = {
            replace: (props: any) => {
                const {attribs, children, name, type, data} = props;

                if (type === "text" && data.split('planvoorstel').length > 1) {
                    const parts = data.split('planvoorstel');
                    return <>{ parts.map((str: string, index: number) => {
                        if ((index + 1) < parts.length) {
                            return <React.Fragment key={index}>
                                {str}
                                <span className="like-link" onClick={() => this.setState({showPropositionForm: true})}>planvoorstel</span>
                            </React.Fragment>
                        }
                        return str;
                    })
                    }</>;
                } else if (name === 'a' && attribs.href && children[0] && children[0].type === 'text') {
                    return <ContentLink href={attribs.href}>{children[0].data}</ContentLink>
                }

                if (attribs && attribs.style) {
                    attribs.style = ((style) => {
                        style = style
                            .trim()
                            .split(' ')
                            .join('" "')
                            .split(':"')
                            .join('":')
                            .split(';')
                            .join('",')
                            .split('","')
                            .join('",');
                        style = '{"' + style.substr(0, style.length - 1) + '}';

                        const object  = JSON.parse(style);
                        const newObject: {[key: string]: string} = {};

                        Object.keys(object).forEach((key: string) => {
                            const value = object[key];
                            key = ((string: string) => {
                                const hyphenPatternRegex = /-([a-z])/g;
                                const CUSTOM_PROPERTY_OR_NO_HYPHEN_REGEX = /^--[a-zA-Z0-9-]+$|^[^-]+$/;

                                // custom property or no hyphen found
                                if (CUSTOM_PROPERTY_OR_NO_HYPHEN_REGEX.test(string)) {
                                    return string;
                                }

                                // convert to camelCase
                                return string
                                    .toLowerCase()
                                    .replace(hyphenPatternRegex, function (_, character) {
                                        return character.toUpperCase();
                                    });
                            })(key);

                            newObject[key] = value;
                        });

                        return newObject;
                    })(attribs.style);
                }
                if (attribs && attribs.class) {
                    attribs.className = attribs.class;
                    delete attribs.class;
                }
                if (attribs && attribs.srcset) {
                    attribs.srcSet = attribs.srcset;
                    delete attribs.srcset;
                }

                if (name) {
                    // if (['img', 'br'].indexOf(name) >= 0) {
                    //     return;
                    // }
                    if (attribs && attribs.className === 'button') {
                        attribs.className = 'button primary';
                    }

                    if (children.length === 0) {
                        return React.createElement(name, attribs);
                    }

                    return React.createElement(name, attribs, domToReact(children, options))
                }
            }
        };

        const content = (() => {
            let content = this.props.children;
            if (typeof content === 'string') {
                content = parse(content, options);
            }
            return content;
        })();

        return content;
    }
    get contactButton() {
        if (this.props.withContactButton) {
            return (
                <div className="text-center">
                    <Contact />
                </div>
            );
        }
        return null;
    }
    get title() {
        if (!this.props.title) {
            return null;
        }
        return <h1 className="page-title">{parse(this.props.title)}</h1>;
    }
    get breadcrumbs() {
        if (this.props.crumbs) {
            return <Breadcrumbs crumbs={this.props.crumbs} />;
        }
        return null;
    }
    get icons() {
        if (!this.props.icons) {
            return undefined;
        }

        return <div className={"icons-wrapper"}>
            {Object.keys(this.props.icons).map((key: string, index: number) => {
                let onClick = () => {};
                let label;
                // @ts-ignore
                if (this.props.icons && this.props.icons[key]) {
                    // @ts-ignore
                    onClick = this.props.icons[key].action;
                    // @ts-ignore
                    label = this.props.icons[key].label;
                }

                return <button key={index} className={'button primary'} onClick={onClick}><span className={"icon-" + (key.split('_').join('-'))} />{label ? <span className="label">{label}</span> : ''}</button>
            })}
        </div>;
    }
    render() {
        return (
            <div className={this.className}>
                <PropositionForm toggle={() => this.setState({showPropositionForm: false})} show={this.state.showPropositionForm} />
                {this.props.icons ? (<>
                    <div className="icons-and-crumbs">
                        {this.breadcrumbs}
                        {this.icons}
                    </div>
                </>) : this.breadcrumbs}

                {this.title}
                {this.content}

                {this.contactButton}
            </div>
        );
    }
}

export function GridContent(props: any) {
    return (
        <div className="grid-page-content">
            {props.children}
        </div>
    );
}
export function LeftContent(props: any) {
    return (
        <div className="left-page-content">
            {props.children}
        </div>
    );
}
export function RightContent(props: any) {
    return (
        <div className="right-page-content">
            {props.children}
        </div>
    );
}
