import React, { ReactElement } from "react";
import './Tag.scss';

interface IProps {
    children: string|ReactElement,
    invisible?: boolean,
    show?: boolean,
    id?: string,
    on?: boolean,
    onClick?: () => void,
    primary?: boolean,
    hollow?: boolean,
    className?: string,
}
interface IState {
    
}

class Tag extends React.Component<IProps> {
    static defaultProps: any = {
        show: true
    };

    constructor(props: IProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    get className() {
        const classes = ['tag'];

        if (this.props.invisible) {
            classes.push('tag-invisible');
        }
        if (this.props.primary) {
            classes.push('tag-primary');
        }
        if (this.props.hollow) {
            classes.push('tag-hollow');
        }
        if (this.props.onClick) {
            classes.push('clickable');
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }

        return classes.join(' ');
    }

    get style() {
        const style = {} as any;
        if (!this.props.show) {
            style['display'] = 'none';
        }
        return style;
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div id={this.props.id} className={this.className} style={this.style} onClick={this.handleClick}>
                {this.props.children}
            </div>
        );
    }
}

export default Tag;