export function getBasePath(url: string): string {
    let host = window.location.host;
    if (parseInt(window.location.port) >= 3000) {
        host = 'uwcasa.nl';
    }
    const parts = url.split("https://" + host);

    if (parts.length > 1) {
        url = parts[1];
    }
    return url;
}