import React from "react";
import "./Select.scss";

interface IProps {
    children: any,
    onChange?: any,
    value?: string|number|null
}

export function Select(props: IProps) {
    const id: string = btoa((Math.floor(Math.random() * 20000) + 1).toString()).split('=').join('');

    return (
        <div className="select-wrapper">
            <select id={id} onChange={(event: any) => { props.onChange(event); }} value={props.value !== null ? props.value : ''}>
                {props.children}
            </select>
            <label htmlFor={id} />
        </div>
    );
}