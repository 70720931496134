import React from "react";
import './PageImage.scss';
import { wp } from "../../Factories/Wordpress";

interface IProps {
    src: 'auto'|string,
    small?: boolean,
    noBlur?: boolean,
}
interface IState {
    parallaxOffset: number,
    src?: string
}

export class PageImage extends React.Component<IProps, IState>{
    private timeout: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            parallaxOffset: 0,
            src: props.src !== 'auto' ? props.src : ''
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.getRandomImage = this.getRandomImage.bind(this);
    }

    get className(): string {
        const classes = ['page-image-wrapper'];

        if (this.props.small) {
            classes.push('small-page-image');
        }
        if (this.props.noBlur) {
            classes.push('no-blur');
        }

        return classes.join(' ');
    }

    get src(): string|undefined {
        return this.state.src;
    }

    componentDidMount(): void {
        if (!this.state.src) {
            this.getRandomImage();
        }
        window.addEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.src !== this.props.src) {
            this.setState({src: this.props.src});
        }
    }

    componentWillUnmount(): void {
        if (this.timeout) clearTimeout(this.timeout);
        window.removeEventListener('scroll', this.handleScroll);
    }

    getRandomImage() {
        wp().frontImages.get().then((images) => {
            const index = Math.floor(Math.random() * images.length);
            this.setState({src: images[index].small});
            this.timeout = setTimeout(() => {
                this.setState({src: images[index].full});
            }, 500);
        });
    }

    handleScroll(event: Event) {
        const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;


        this.setState({parallaxOffset: scrollTop / 4});
    }

    render() {

        return (
            <div className={this.className}>
                <div className="page-image" style={{backgroundImage: 'url(' + this.src + ')', transform: 'translateY(' + this.state.parallaxOffset + 'px)'}} />
            </div>
        );
    }
}