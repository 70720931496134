import React from "react";
import {Popup} from "../../Popup/Popup";
import Input from "../../Input/Input";
import {Form} from "../../Form/Form";
import {wp} from "../../../Factories/Wordpress";
import {Error} from "../../Error/Error";

interface IProps {
    toggle: any,
    show: boolean,
    context?: string|number
}
interface IState {
    isLoading: boolean
    done: boolean
    error?: string
}

export class ContactForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            done: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(data: any) {
        this.setState({isLoading: true, error: undefined});

        wp().submissions.post(data)
            .then((answer: any) => {
                this.setState({
                    isLoading: false,
                    done: true,
                }, () => {
                    setTimeout(this.props.toggle, 2500);
                })
            }).catch((response: Response) => {
            response.text().then((error: any) => {
                try {
                    const parsed = JSON.parse(error);

                    if (parsed.ip) {
                        this.setState({isLoading: false, error: 'U kunt maar 1x per half uur een formulier insturen. Probeer het later nog eens.'})
                    } else {
                        this.setState({isLoading: false, error: Object.keys(parsed)[0]});
                    }
                } catch (e) {
                    this.setState({isLoading: false, error});
                }
            });
        });
    }
    render() {
        return (
            <Popup toggle={this.props.toggle} show={this.props.show}>
                <h1>Contact opnemen.</h1>
                {this.state.error ? <Error>{this.state.error}</Error> : ''}
                {!this.state.isLoading && !this.state.done ? (
                    <Form onSubmit={this.handleSubmit}>
                        <Input label="Uw naam" required={true}>
                            <input type="text" placeholder="Hendrik Huis" name="name"/>
                        </Input>
                        <Input label="Uw email" required={true}>
                            <input type="email" placeholder="hendrik@huis.nl" name="email"/>
                        </Input>
                        <Input label="Uw telefoonnummer">
                            <input type="text" placeholder="+316 00 00 00 00" name="phone"/>
                        </Input>
                        <Input label="Uw vraag">
                            <textarea name="message" placeholder="Laat een bericht achter" />
                        </Input>
                        <Input label={<>Ik ga akkoord met <a target="_blank" href="https://uwcasa.nl/wp/wp-content/uploads/2018/06/privacy.pdf">de voorwaarden</a>.</>} required={true}>
                            <input type="checkbox" name="agreement"/>
                        </Input>
                        <div className="text-center">
                            <button className="button secondary" type="submit">Contact opnemen</button>
                        </div>
                        {this.props.context ? <input type="hidden" name="context" value={this.props.context}/> : ''}
                    </Form>
                ) : (
                    this.state.isLoading ? (`Bezig met laden`) : (`Formulier in goede orde ontvangen`)
                )}

            </Popup>
        );
    }
}