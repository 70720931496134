import "./Datepicker.scss";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { MonthSelect } from "../MonthSelect/MonthSelect";

interface IProps {
    name: string
    range?: boolean
    value?: Date|Date[]
    onChange?: ChangeEventHandler<any>
    required?: boolean
}

export function Datepicker(props: IProps) {
    const [month, setMonth] = useState((new Date()).getMonth() + 1);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [from, setFrom] = useState<Date|undefined>();

    let input: HTMLInputElement | null = null;

    if (month < 1) {
        setYear(year - 1);
        setMonth(12);
    } else if (month > 12) {
        setYear(year + 1);
        setMonth(1);
    }

    const isCurrentMonth = (new Date()).getMonth() + 1 === month && (new Date()).getFullYear() === year;
    const isLastMonth = month === 12 && year === (new Date()).getFullYear() + 1;
    const firstDate = new Date(year, month - 1);
    const daysInThisMonth = ((thisMonth: Date) => {
        const nextMonth = new Date(thisMonth);
        nextMonth.setMonth(thisMonth.getMonth() + 1);
        nextMonth.setDate(-1);
        return nextMonth.getDate();
    })(firstDate);
    const day = firstDate.getDay();
    const numberOfWeeks = Math.ceil(((day === 0 ? 7 : day) + daysInThisMonth) / 7);
    firstDate.setDate((day === 0 ? 5 : (day - 2)) * -1);


    useEffect(() => {
        if (!from || !input) {
            return;
        }

        const d = (() => {
            let value = from.getDate().toString();
            if (value.length === 1) {
                value = '0' + value;
            }
            return value;
        })();
        const m = (() => {
            let value = (from.getMonth() + 1).toString();
            if (value.length === 1) {
                value = '0' + value;
            }
            return value;
        })();
        const y = from.getFullYear();

        // @ts-ignore
        const setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        // @ts-ignore
        setValue.call(input, d + '-' + m + '-' + y);
        const event = new Event('input', { bubbles: true });
        input.dispatchEvent(event);

    }, [from, props, input]);

    return (
        <div className={"datepicker-wrapper"}>
            <div className="year-month-wrapper">
                <span
                    onClick={isCurrentMonth ? undefined : () => setMonth(month - 1)}
                    className={isCurrentMonth ? 'disabled' : undefined}
                />
                <MonthSelect month={month} year={year} future={true} onChange={(event) => setMonth(parseInt(event.target.value))} />
                <select onChange={(event) => setYear(parseInt(event.target.value))} value={year}>
                    {(Array.apply(null, Array(2))).map((nothing: any, index: number) => {
                        return <option key={index} value={(new Date()).getFullYear() + index}>{(new Date()).getFullYear() + index}</option>
                    })}
                </select>
                <span
                    onClick={isLastMonth ? undefined : () => setMonth(month + 1)}
                    className={isLastMonth ? 'disabled' : undefined}
                />
            </div>
            <div className="picker-content" data-rows={1 + numberOfWeeks}>
                {['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'].map((day: string, index: number) => {
                    return <span className={"day-indicator"} key={index}>{day}</span>
                })}
                {(Array.apply(null, Array(numberOfWeeks))).map((nothing: any, rowIndex: number) => {
                    return <React.Fragment key={rowIndex}>
                        {(Array.apply(null, Array(7))).map((nothing: any, dayIndex: number) => {
                            const day = new Date(firstDate);
                            day.setDate(day.getDate() + dayIndex + (rowIndex * 7));
                            const isHistory = day < (new Date());
                            const isToday = day.getMonth() === (new Date()).getMonth() && day.getFullYear() === (new Date()).getFullYear()  && day.getDate() === (new Date()).getDate();
                            const isOtherMonth = day.getMonth() !== (month - 1);

                            const classes = ["day"];
                            if (isToday) {
                                classes.push('is-today');
                            }
                            if (isHistory) {
                                classes.push("is-history");
                            }
                            if (isOtherMonth) {
                                classes.push("is-other-month");
                            }
                            if (!props.range && from) {
                                if (day.getMonth() === from.getMonth() && day.getFullYear() === from.getFullYear()  && day.getDate() === from.getDate()) {
                                    classes.push("is-selected");
                                }
                            }

                            return <span
                                key={dayIndex}
                                className={classes.join(' ')}
                                onClick={isHistory ? undefined : () => {setFrom(day)}}
                            >{day.getDate()}</span>;
                        })}
                    </React.Fragment>
                })}
            </div>
            <input type="text"
                   name={props.name}
                   required={props.required}
                   onFocus={(event) => event.target.blur()}
                   onChange={props.onChange}
                   ref={(elm) => input = elm}
            />
        </div>
    );
}