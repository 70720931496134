import React from 'react';
import {HouseQuery} from "../../Interfaces/HouseQuery";
import HouseWidget from "../../Components/HouseWidget/HouseWidget";
import {IHouse} from "../../Interfaces/IHouse";
import './HouseBar.scss';
import {wp} from "../../Factories/Wordpress";

interface IProps {
    title: string,
    query: HouseQuery,
    delayed?: boolean
    desiredLength?: number
}

interface IState {
    isFetching: boolean,
    houses?: IHouse[]
}

class HouseBar extends React.Component<IProps, IState> {
    private abortController = new AbortController();
    private aborted = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isFetching: false
        };
    }

    get houses() {
        if (!this.state.houses || this.state.isFetching) {
            return (new Array(this.props.desiredLength ? this.props.desiredLength : 4).fill(0)).map((value, index) => {
                return <HouseWidget key={index}/>
            });
        }
        return this.state.houses.map((house: IHouse) => {
            return <HouseWidget key={house.id} house={house}/>
        })
    }

    componentDidMount(): void {
        const delay = this.props.delayed ? 50 : 0;
        setTimeout(() => {
            wp().houses.get(this.props.query as {}, this.abortController.signal).then((answer: IHouse[]) => {
                if (!this.aborted) {
                    this.setState({houses: answer});
                }
            }).catch((error: any) => {
                console.log({error});
            });
        }, delay);
    }

    componentWillUnmount(): void {
        this.aborted = true;
        this.abortController.abort();
    }


    render() {
        return (
            <div className="house-bar-container">
                <h1>{this.props.title}</h1>
                <div className="house-bar-wrapper">
                    <div className="house-bar">
                        {this.houses}
                    </div>
                </div>
            </div>
        );
    }
}

export default HouseBar;