import "./Favourites.scss";
import React, {useEffect, useState} from "react";
import {wp} from "../../../Factories/Wordpress";
import {IHouse} from "../../../Interfaces/IHouse";
import HouseWidget from "../../../Components/HouseWidget/HouseWidget";

interface IProps {

}

export function Favourites(props: IProps) {
    const [houses, setHouses] = useState();
    const [isLoading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        if (!houses && !isLoading) {
            setIsLoading(true);
            wp().houses.get({favourites: true}).then((houses: IHouse[]) => {
                setHouses(houses.map((house: IHouse) => {
                    return <HouseWidget key={house.id} house={house} />;
                }));
                setIsLoading(false);
            });
        }
    }, [houses, isLoading]);


    return (
        <>
            <h1>Uw favorieten.</h1>
            {!isLoading && houses ? (
                <div className="favourites-wrapper">
                    {houses}
                </div>
            ) : ''}

        </>
    );
}