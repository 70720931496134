import {IHouseType} from "../../Interfaces/IHouseType";
import { getReadablePrice } from "../../Helpers/numbers";
import React, { ReactElement } from "react";
import {ILocationFilter} from "../../Interfaces/IHouseFilter";

export interface IFilterIndicator {
    name: string,
    value?: string|ReactElement,
    key: string,
    metaKey?: string,
}

export class Filters {
    static all() {
        const filters = window.localStorage.getItem('filters');
        if (filters) {
            return JSON.parse(filters);
        }
        return {};
    }
    static get(key: string) {
        const filters = Filters.all();
        if (Filters.isset(key)) {
            return filters[key];
        }
        return Filters.default(key);
    }
    static remove(key: string) {
        if (key === 'location') {
            this.remove('provinces');
            this.remove('cities');
            return;
        }

        const filters = Filters.all();
        const newFilters = {} as any;
        (Object.keys(filters)).forEach((foundKey: string) => {
           if (foundKey !== key) {
               newFilters[foundKey] = filters[foundKey];
           }
        });

        localStorage.setItem('filters', JSON.stringify(newFilters));
    }
    static isset(key: string): boolean {
        const filters = Filters.all();
        if  (key === 'pool') {
            return filters[key] !== undefined;
        }
        if (key === 'location') {
            return Filters.isset('provinces') || Filters.isset('cities');
        }
        if (key === 'price') {
            return filters[key] && (!!filters[key][0] || !!filters[key][1]);
        }
        if (filters[key] && typeof filters[key] === 'object') {
            return filters[key].length > 0;
        }
        return !!filters[key];
    }
    static set(key: string, value: string|string[]|number|number[]|boolean) {
        const filters = Filters.all();
        filters[key] = value;
        localStorage.setItem('filters', JSON.stringify(filters));
    }
    static getFilterIndicators(): IFilterIndicator[] {
        return [{
            name: 'Type',
            key: 'type'
        }, {
            name: 'Prijs',
            key: 'price',
            metaKey: 'prijs'
        }, {
            name: 'Badkamers',
            // value: '2 badkamers',
            key: 'baths',
            metaKey: 'badkamers',
        }, {
            name: 'Slaapkamers',
            key: 'beds',
            metaKey: 'slaapkamers',
        }, {
            name: 'Zwembad',
            key: 'pool',
            metaKey: 'zwembad',
        }, {
            name: 'Locatie',
            key: 'location',
        }].map((indicator: IFilterIndicator) => {
            if (Filters.isset(indicator.key)) {
                indicator.value = Filters.getFilterValueText(indicator.key);
            }
            return indicator;
        })
    }
    static getFilterIndicator(key: string): IFilterIndicator {
        if (['provinces', 'cities'].includes(key)) {
            key = 'location';
        }
        return Filters.getFilterIndicators()
            .filter((indicator: IFilterIndicator) => indicator.key === key)[0];
    }
    static getFilterValueText(key: string): string|ReactElement {
        const v = Filters.get(key);
        switch (key) {
            case 'type':
                return filterTypeOptions
                    .filter((option: IHouseType) => {
                        return v.includes(option.id);
                    })
                    .map((option: IHouseType) => {
                        return option.name
                    })
                    .join(', ');
            case 'price':
                if (v[0] || v[1]) {
                    let a = <>Onbeperkt</>;
                    if (v[0]) {
                        a = <>&euro; {getReadablePrice(v[0])}</>;
                    }
                    let b = <>Onbeperkt</>;
                    if (v[1]) {
                        b = <>&euro; {getReadablePrice(v[1])}</>;
                    }

                    return <React.Fragment>{a} - {b}</React.Fragment>;
                }
                return '';
            case 'baths':
            case 'beds':
                let name = key === 'baths' ? 'Badkamers' : 'Slaapkamers';
                return <>{v}+ {name}</>;
            case 'pool':
                return (v ? 'Met' : 'Zonder') + ' zwembad';
            case 'location':
                let names: any = [];
                const provinces = Filters.get('provinces');
                if (!!provinces) {
                    names = names.concat(provinces.map((province: ILocationFilter) => {
                        return province.name;
                    }));
                }
                const cities = Filters.get('cities');
                if (!!cities) {
                    names = names.concat(cities.map((city: ILocationFilter) => {
                        return city.name;
                    }));
                }

                let str = '';
                let longEnough = false;
                let more = 0;
                names.forEach((name: string) => {
                    if (longEnough) {
                        more++;
                        return;
                    }
                    str += name + ', ';
                    if (str.length > 25) {
                        longEnough = true;
                    }
                });
                str = str.split('').reverse().join('').substr(2).split('').reverse().join('');
                if (more > 0) {
                    str += '... (+' + more + ')';
                }

                return str;
            default:
                return v;
        }
    }
    static default(key: string) {
        if (key === 'type') {
            return [];
        }
        return undefined;
    }
    static forQuery() {
        const all = Filters.all();

        all.location = [all.provinces, all.cities];

        all.provinces = undefined;
        all.cities = undefined;

        return Object.keys(all).filter((key: string) => {
            if (key === 'pool') {
                return all[key] !== undefined;
            }
            if (['cities', 'provinces'].includes(key)) {
                return false;
            }
            return !!all[key];
        }).filter((key: string) => {
            return Filters.getFilterIndicator(key) !== undefined;
        }).filter((key: string) => {
            if (key === 'price') {
                return Filters.isset(key);
            }
            if (Filters.isset(key) && typeof all[key] === 'object') {
                return all[key].length > 0
            }
            return true;
        }).map((key: string) => {
            const value = Filters.get(key);
            const indicator = Filters.getFilterIndicator(key);
            let args = {
                key: 'woning_' + (indicator.metaKey ? indicator.metaKey : key),
                value,
                compare: '='
            } as any;

            switch (key) {
                case 'type':
                    args.compare = 'IN';
                break;
                case 'price':
                    args.compare = 'BETWEEN';
                    args.type = 'numeric';
                    if (args.value[0] === null || args.value[0] === 50000) {
                        args.value[0] = 0;
                    }
                    if (args.value[1] === null || args.value[0] === 2500000) {
                        args.value[1] = 99999999;
                    }
                break;
                case 'baths':
                case 'beds':
                    args.compare = '>=';
                    args.type = 'numeric';
                break;
                case 'pool':
                    args.type = 'numeric';
                    args.value = value ? 1 : 0;
                break;
                case 'location':
                    args.compare = 'IN';

                    let provinces = Filters.get('provinces');
                    args.value = [];
                    if (provinces) {
                        provinces = (provinces as ILocationFilter[]).map((province: ILocationFilter) => {
                            return province.id;
                        }) as number[];

                        args.value = args.value.concat(provinces);
                    }
                    let cities = Filters.get('cities');
                    if (cities) {
                        cities = (cities as ILocationFilter[]).map((city: ILocationFilter) => {
                            return city.id;
                        }) as number[];

                        args.value = args.value.concat(cities);
                    }
                break;
            }
            return args;
        }).filter((args: any) => {
            if (typeof args.value === 'object') {
                return args.value.length > 0;
            }
            return !!args.value;
        });
    }
}

export const filterTypeOptions: IHouseType[] = [{
    id: 1,
    name: 'Appartement',
}, {
    id: 7,
    name: 'Bungalow'
}, {
    id: 3,
    name: 'Finca',
}, {
    id: 4,
    name: 'Penthouse',
}, {
    id: 5,
    name: 'Vakantiehuis',
}, {
    id: 6,
    name: 'Villa',
}];