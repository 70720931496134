import React from "react";
import {PageImage} from "../../Components/PageImage/PageImage";
import {PageContent} from "../../Components/PageContent/PageContent";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import PostBar from "../../Containers/PostBar/PostBar";
import {PostQuery} from "../../Interfaces/PostQuery";
import {IProvince} from "../../Interfaces/IProvince";
import {wp} from "../../Factories/Wordpress";
import {NavLink} from "react-router-dom";
import DangerouslySetHtmlContent from "dangerously-set-html-content";
import { Houses } from "../Houses/Houses";

interface IProps {
    match: {path: string},
}
interface IState {
    province?: IProvince
}

export class Province extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }
    componentDidMount(): void {
        this.retrieve();
    }

    retrieve() {
        wp().locations.get().then((provinces: IProvince[]) => {
            const province = provinces.filter((province: IProvince) => {
                return province.slug === this.slug;
            })[0];

            this.setState({province});

            window.scrollTo(0,0);
        });
    }

    get slug() {
        const path = this.props.match.path.split('/');
        return path[path.length - 1];
    }


    get breadcrumbs() {
        if (!this.state.province) {
            return null;
        }
        return [
            <li>
                <NavLink to={'/aanbod/' + this.slug}>{this.state.province.name}</NavLink>
            </li>
        ];
    }

    get contents() {
        if (this.state.province) {
            const province = this.state.province;

            return (
                <React.Fragment>
                    <PageImage src={'https://source.unsplash.com/1600x900/?spanish%20villa,spain%20beach,spanish%20streets,spain%20nature&sig=' + (Math.round(Math.random() * 10000))} />
                    <PageContent title={province.name} withContactButton={true} crumbs={this.breadcrumbs}>
                        <DangerouslySetHtmlContent html={province.description} />
                        <div className="inline-houses-wrapper">
                            <Houses inline={true} province={province.term_id} />
                        </div>
                    </PageContent>
                </React.Fragment>
            );
        }
        return null;
    }
    get postsQuery() {
        return {
            post_type: 'post',
            category_name: 'Informatie',
            limit: 4
        } as PostQuery
    }

    render() {
        return (
            <div className="post-wrapper">
                <ScrollToTopOnMount/>
                {this.contents}
                <PostBar title="Wellicht interessant." query={this.postsQuery}/>
            </div>
        );
    }
}