import React from "react";
import "./FiltersForm.scss";
import {TypeFilter} from "./TypeFilter/TypeFilter";
import {IHouseFilter, ILocationFilter} from "../../Interfaces/IHouseFilter";
import { Filters, IFilterIndicator } from "../../Core/Filters/Filters";
import {PriceFilter} from "./PriceFilter/PriceFilter";
import {CountFilter} from "./CountFilter/CountFilter";
import {BooleanFilter} from "./BooleanFilter/BooleanFilter";
import {LocationFilter} from "./LocationFilter/LocationFilter";

interface IProps {
    show: boolean,
    changingFilter: string,
    onChange: (indicators: IFilterIndicator[]) => void,
    onClose: () => void
}
interface IState {
    changingFilter?: string,
    filters?: any
}

export class FiltersForm extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {};

        this.closeForm = this.closeForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    get className() {
        const classes = ['filters-form-wrapper'];

        if (this.props.show && this.props.changingFilter) {
            classes.push('show');
        }

        return classes.join(' ');
    }
    get storedFilters() {
        return Filters.all();
    }

    get formPart() {
        const key = this.props.changingFilter;
        const indicator = Filters.getFilterIndicator(key);
        let input = null;
        switch (key) {
            case 'type':
                input = <TypeFilter value={this.getFilter('type')} onChange={this.handleChange} />;
            break;
            case 'price':
                input = <PriceFilter value={this.getFilter('price')} onChange={this.handleChange} />;
            break;
            case 'baths':
            case 'beds':
                input = <CountFilter single={key === 'baths' ? 'badkamer' : 'slaapkamer'} value={this.getFilter(key).value} onChange={(newValue: any) => { this.handleChange(key, newValue); }}/>;
            break;
            case 'pool':
                let value = this.getFilter(key).value;
                if (value !== undefined) {
                    value = !!value;
                }
                input = <BooleanFilter value={value} onChange={(newValue: any) => { this.handleChange(key, newValue); }} />;
            break;
            case 'location':
                let provinces = this.getFilter('provinces').value;
                if (provinces) {
                     provinces = (provinces as ILocationFilter[]).map((province: ILocationFilter) => {
                        return province.id;
                    }) as number[];
                }
                let cities = this.getFilter('cities').value;
                if (cities) {
                    cities = (cities as ILocationFilter[]).map((city: ILocationFilter) => {
                        return city.id;
                    }) as number[];
                }
                input = (
                    <>
                        <LocationFilter onChange={(newValue: any) => { this.handleChange('provinces', newValue); }} value={provinces as number[]} type={"provinces"} />
                        <LocationFilter onChange={(newValue: any) => { this.handleChange('cities', newValue); }} value={cities as number[]} parent={provinces as number[]} type={"cities"} />
                    </>
                );
            break;
            default:
            break;
        }
        return (
            <React.Fragment>
                <h1>{indicator.name}</h1>
                {input}
            </React.Fragment>
        );
    }

    componentDidMount(): void {
        this.setState({filters: this.storedFilters});
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({filters: this.storedFilters});
        }
    }

    closeForm() {
        this.props.onClose();
    }


    getFilter(key: string): IHouseFilter {
        let value;
        if (this.state.filters && this.state.filters[key] !== undefined) {
            value = this.state.filters[key];
        }
        return {key: key, value: value};
    }

    setFilter(key: string, value: string|string[]|number|number[]|boolean) {
        Filters.set(key, value);
        this.props.onChange(Filters.getFilterIndicators());
        this.setState({filters: this.storedFilters});
    }

    handleChange(key: string, newValue: any) {
        this.setFilter(key, newValue);
    }

    render() {
        return (
            <div className={this.className}>
                <div className="form-box">
                    <div className="close-button" onClick={this.closeForm} />
                    <div className="form-box-contents">
                        {this.formPart}
                    </div>
                </div>
            </div>
        );
    }
}