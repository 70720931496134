import React, {ReactNode} from "react";
import './Image.scss';

interface IProps {
    src?: string
    thumbnail?: string
    alt?: string,
    onClick?: any,
    overlay?: ReactNode
}
interface IState {
    src?: string
}

class Image extends React.Component<IProps, IState> {
    public timeout: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            src: props.src
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.src !== this.props.src) {
            this.setSource();
        }
    }

    componentDidMount(): void {
        this.setSource();
    }
    componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    setSource() {
        if (this.props.thumbnail) {
            this.setState({src: this.props.thumbnail});
            this.timeout = setTimeout(() => {
                if (this.props.src) {
                    this.setState({src: this.props.src});
                }
            }, 100);
        } else {
            this.setState({src: this.props.src});
        }
    }

    get src() {
        return this.state.src;
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }


    render() {
        return this.state.src ? (
                <div className="image">
                    <img src={this.state.src} alt={this.props.alt} onClick={this.handleClick} />
                    {this.props.overlay}
                </div>
            ) : null
    }
}

export default Image;