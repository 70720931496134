import React, {ReactNode} from "react";
import "./MobileMenu.scss";

interface IProps {
    menuOpen: boolean
    children: ReactNode|ReactNode[]
    onClick: () => void
}

export function MobileMenu(props: IProps) {
    const classes = ['mobile-menu'];
    if (props.menuOpen) {
        classes.push('open');
    }
    return (
        <>
            <div className={classes.join(' ')} onClick={props.onClick}>
                {props.children}
            </div>
            <div className="menu-background" onClick={props.onClick} />
        </>
    );
}