import React from "react";
import "./RemoveButton.scss";

interface IProps {
    onClick: (e?: any) => void
}

export function RemoveButton(props: IProps) {
    return (
        <div className={"remove-button"} onClick={props.onClick} />
    );
}