import React, { useState } from 'react';
import {SliderInput} from "../../SliderInput/SliderInput";

interface IProps {
    onChange: (newValue: any) => void,
    value: any,
    single: string,
    multiple?: string
}

export function CountFilter(props: IProps) {
    const [value, setValue] = useState(props.value ? props.value : 0);

    if (value !== props.value) {
        setValue(props.value);
    }

    const onChange = (newValue: number) => {
        setValue(newValue);
        props.onChange(newValue > 0 ? newValue : null);
    };

    return (
        <>
            {value > 0 ? (
                <p>Mijn woning moet minimaal {value} {value === 1 ? props.single : (props.multiple ? props.multiple : (props.single + 's'))} bevatten.</p>
            ) : (
                <p>Geen voorkeur opgegeven.</p>
            )}
            <SliderInput min={0} max={10} onChange={onChange} range={false} value={value} step={1} />
        </>
    );
}