import React from "react";
import "./FancyLabel.scss";

interface IProps {
    text: string
    onImage?: boolean
    colorType: "blue"|"orange"|"green"|"yellow"|"red"
}

export function FancyLabel(props: IProps) {
    const classes = ['fancy-label'];

    if (props.onImage) {
        classes.push('on-image');
    }
    if (props.colorType) {
        classes.push('color-' + props.colorType);
    }

    return (
        <div className={classes.join(' ')}>
            <div className="fancy-label-contents">
                {props.text}
            </div>
        </div>
    );
}