import React from "react";
import "./PropositionForm.scss";
import {Popup} from "../../Popup/Popup";
import Input from "../../Input/Input";
import {Form} from "../../Form/Form";
import {wp} from "../../../Factories/Wordpress";
import {Error} from "../../Error/Error";
import { Datepicker } from "../../Datepicker/Datepicker";
import { MonthSelect } from "../../MonthSelect/MonthSelect";
import { Choice } from "../../Choice/Choice";

interface IProps {
    toggle?: any,
    show: boolean,
    context?: string|number
}
interface IState {
    isLoading: boolean
    done: boolean
    error?: string
}

export class PropositionForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            done: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(data: any) {
        data.form = 'Planvoorstel';
        this.setState({isLoading: true, error: undefined});


        wp().submissions.post(data)
            .then((answer: any) => {
                this.setState({
                    isLoading: false,
                    done: true,
                }, () => {
                    setTimeout(this.props.toggle, 2500);
                })
            }).catch((response: Response) => {
            response.text().then((error: any) => {
                try {
                    const parsed = JSON.parse(error);

                    if (parsed.ip) {
                        this.setState({isLoading: false, error: 'U kunt maar 1x per half uur een formulier insturen. Probeer het later nog eens.'})
                    } else {
                        this.setState({isLoading: false, error: Object.keys(parsed)[0]});
                    }
                } catch (e) {
                    this.setState({isLoading: false, error});
                }
            });
        });
    }

    getContext() {
        const hashContext = window.location.hash.substring(1);
        if (hashContext.length > 0) {
            return parseInt(hashContext);
        }
        return this.props.context;
    }

    get form() {
        return (
            <>
                {this.state.error ? <Error>{this.state.error}</Error> : ''}
                {!this.state.isLoading && !this.state.done ? (
                    <Form onSubmit={this.handleSubmit}>
                        {this.getContext() ? (<p>Ja, ik wil <b>deze woning</b> graag bezichtigen met een meertalige medewerker van UwCasa op</p>) : (<p>Ja, ik wil graag mijn favoriete woningen bezichtigen met een meertalige medewerker van UwCasa op</p>)}
                        <Datepicker name={"date"} onChange={(event) => console.log(event.target.value)} required={true}/>
                        {this.getContext() ? (<p>Wanneer deze woning mij bevalt en al mijn overige vragen zijn beantwoord, zouden we deze willen aanschaffen in de maand</p>) : (
                            <p>Het zou mooi zijn als we een woning kunnen aanschaffen in de maand</p>
                        )}
                        <MonthSelect name={"deadline_month"} month={new Date().getMonth() + 1} />
                        <Input label="Uw naam" required={true}>
                            <input type="text" placeholder="Hendrik Huis" name="name"/>
                        </Input>
                        <Input label="Uw email" required={true}>
                            <input type="email" placeholder="hendrik@huis.nl" name="email"/>
                        </Input>
                        <Input label="Uw telefoonnummer">
                            <input type="text" placeholder="+316 00 00 00 00" name="phone"/>
                        </Input>
                        <Input label="Uw opmerkingen">
                            <textarea name="message" placeholder="Voer uw opmerkingen in" />
                        </Input>
                        <Choice label={"Financiering"} options={{none: 'Niet nodig', need: 'Nodig', noAnswer: 'Zeg ik liever niet'}} name={"finance"} />
                        <Input label={<>Ik heb begrepen wat er komt kijken bij het <a href="/informatie/uw-koopproces-in-spanje/" target="_blank">koopproces</a> van een eigen woning in Spanje.</>} required={true}>
                            <input type="checkbox" name="understand"/>
                        </Input>
                        <Input label={<>Ik ga ervan uit dat mijn gegevens strikt voor het voornoemde doel door UwCasa worden beheerd, en conform de AVG-wet.</>} required={true}>
                            <input type="checkbox" name="avg"/>
                        </Input>
                        <Input label={<>Ik ga akkoord met <a target="_blank" href="https://uwcasa.nl/wp/wp-content/uploads/2018/06/privacy.pdf">de voorwaarden</a>.</>} required={true}>
                            <input type="checkbox" name="agreement"/>
                        </Input>
                        <div className="text-center">
                            <button className="button secondary" type="submit">Contact opnemen</button>
                        </div>
                        {this.getContext() ? <input type="hidden" name="context" value={this.getContext()}/> : ''}
                    </Form>
                ) : (
                    this.state.isLoading ? (`Bezig met laden`) : (`Formulier in goede orde ontvangen`)
                )}
            </>
        );
    }

    render() {
        if (!this.props.toggle) {
            return <div className={'form-container'}>{this.form}</div>;
        }
        return (
            <Popup toggle={this.props.toggle} show={this.props.show}>
                <h1>Planvoorstel.</h1>
                {this.form}
            </Popup>
        );
    }
}
