import React from "react";
import './HouseFiltersWrapper.scss';

interface IProps {
    children?: any
    inline?: boolean
}
interface IState {
    height: number,
    marginTop: number,
}

export class HouseFiltersWrapper extends React.Component<IProps, IState>{
    desiredHeight = 136;
    startingPosition = 0;

    constructor(props: IProps = {}) {
        super(props);

        this.state = {
            height: this.desiredHeight,
            marginTop: 0
        };

        if (window.history.state && window.history.state.houseFiltersWrapper) {
            this.state = window.history.state.houseFiltersWrapper;
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    get height() {
        return this.state.height;
    }
    get topBarHeight() {
        return 51;
    }
    get marginTop() {
        if (this.props.inline) {
            return 0;
        }
        return this.state.marginTop;
    }
    get classes() {
        const classes = ['house-filters-wrapper'];
        if (this.props.inline) {
            classes.push('inline-filters');
        }
        return classes.join(' ');
    }
    componentDidMount(): void {
        setTimeout(() => {
            window.addEventListener('scroll', this.handleScroll);
        }, 50);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return JSON.stringify(nextState) !== JSON.stringify(this.state) || this.props.children !== nextProps.children;
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const newHistoryState = !!window.history.state ? window.history.state : {};
        newHistoryState.houseFiltersWrapper = this.state;
        window.history.replaceState(newHistoryState, 'state');
        this.handleResize();
    }

    handleScroll(event: Event) {
        if (this.props.inline) {
            return;
        }
        const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;
        const direction = scrollTop < this.startingPosition ? 'up' : 'down';

        if (scrollTop < (this.topBarHeight * 2) || (direction === 'up')) {
            this.setState({marginTop: 0});
        } else if (direction === 'down') {
            this.setState({marginTop: this.height * -1 - this.topBarHeight});
        }


        setTimeout(() => {
            this.startingPosition = scrollTop;
        }, 1000);
    }

    handleResize(): void {
        if (this.props.inline) {
            return;
        }
        const q = document.getElementsByClassName('filters-container');
        if (q[0] && q[0].parentElement) {
            this.setState({
                height: q[0].parentElement.clientHeight
            });
        }
    }

    render() {
        return (
            <div className={this.classes} style={{height: this.props.inline ? 'auto' : this.height}}>
                <div className="house-filters" style={{marginTop: this.marginTop}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}