import React, {useEffect, useState} from "react";
import {Login} from "./Login/Login";
import {Register} from "./Register/Register";
import {Forgot} from "./Forgot/Forgot";

interface IProps {
    onLogin: () => void
    onReset: () => void
}

export function Auth(props: IProps) {
    const [activeScreen, setActiveScreen] = useState('login');
    const [contents, setContents] = useState();
    const [links, setLinks] = useState();

    useEffect(() => {
        const forgotLink = <button onClick={() => setActiveScreen('forgot')}>Wachtwoord vergeten</button>;
        const registerLink = <button onClick={() => setActiveScreen('register')}>Registreren</button>;
        const loginLink = <button onClick={() => setActiveScreen('login')}>Inloggen</button>;

        switch (activeScreen) {
            case 'register':
                setLinks([
                    <React.Fragment key={0}>{loginLink}<br /></React.Fragment>,
                    <React.Fragment key={1}>{forgotLink}</React.Fragment>
                ]);
                setContents(<Register/>);
            break;
            case 'forgot':
                setLinks([
                    <React.Fragment key={0}>{loginLink}<br /></React.Fragment>,
                    <React.Fragment key={1}>{registerLink}</React.Fragment>
                ]);
                setContents(<Forgot onSubmit={props.onReset} />);
            break;
            case 'login':
            default:
                setLinks([
                    <React.Fragment key={0}>{forgotLink}<br /></React.Fragment>,
                    <React.Fragment key={1}>{registerLink}</React.Fragment>
                ]);
                setContents(<Login onLogin={props.onLogin} />);
            break;
        }
    }, [activeScreen, props.onLogin, props.onReset]);

    return (
        <div className="auth-wrapper">
            {contents}
            {links}
        </div>
    );
}