import React from "react";
import {IHouseProperty} from "../../Interfaces/IHouseProperty";
import Tag from "../Tag/Tag";

interface IProps {
    property: IHouseProperty
}
interface IState {

}

class HouseTag extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    get string() {
        let str = this.props.property.name;
        if (this.props.property.value) {
            str = str + ': ' + this.props.property.value;
        }
        return str;
    }

    render() {
        return (
            <Tag className={this.props.property.slug}>{this.string}</Tag>
        );
    }
}

export default HouseTag;