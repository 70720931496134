import React from "react";
import "./Popup.scss";

interface IProps {
    children: any,
    toggle: any,
    show: boolean,
}
interface IState {
    isHiding: boolean
}

export class Popup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isHiding: false
        }
    }
    get className() {
        const classes = ["popup-wrapper"];

        if (this.state.isHiding) {
            classes.push('hiding');
        }

        return classes.join(' ');
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (!this.props.show && prevProps.show !== this.props.show) {
            this.setState({isHiding: true}, () => {
                setTimeout(() => {
                    this.setState({isHiding: false});
                }, 250);
            });
        }
    }

    render() {
        if (!this.props.show && !this.state.isHiding) {
            return <></>;
        }
        return (
            <>
                <div className={this.className}>
                    <div className="close-button" onClick={this.props.toggle} />
                    <div className="popup-contents-wrapper">
                        {this.props.children}
                    </div>
                </div>
                <div className="popup-background" />
            </>
        );
    }
}
