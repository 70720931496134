import React from "react";
import {ContactForm} from "./ContactForm/ContactForm";

interface IProps {
    about?: string|number,
    inline?: boolean,
    initialState?: boolean,
    text?: string
}
interface IState {
    showForm: boolean
}

export class Contact extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showForm: !props.initialState ? false : props.initialState
        };

        this.toggleShowForm = this.toggleShowForm.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.initialState !== this.props.initialState && this.props.initialState !== this.state.showForm) {
            this.setState({showForm: !this.props.initialState ? false : this.props.initialState});
        }
    }

    get buttonClass() {
        let className = '';
        if (this.props.inline) {
            className = 'inline'
        } else {
            className = 'button secondary';
        }
        return className;
    }

    toggleShowForm() {
        this.setState({showForm: !this.state.showForm});
    }

    render() {
        return (
            <>
                <button className={this.buttonClass} onClick={this.toggleShowForm}>{this.props.text ? this.props.text : 'Contact opnemen'}</button>
                <ContactForm toggle={this.toggleShowForm} show={this.state.showForm} context={this.props.about} />
            </>
        );
    }
}