import React from "react";
import {PageContent} from "../../../Components/PageContent/PageContent";
import {PageImage} from "../../../Components/PageImage/PageImage";
import {EditPassword} from "../EditPassword/EditPassword";

interface IProps {
    history: any
}

export function ResetPassword(props: IProps) {

    const onSuccess = () => {
        setTimeout(() => {
            props.history.push('/');
        }, 500);
    };

    return (
        <>
            <PageImage noBlur={true} src={'auto'} small={true} />
            <PageContent crumbs={false}>
                <div className="row">
                    <div className="column small-12 medium-8 medium-push-2 large-6 large-push-3">
                        <EditPassword reset={true} onSuccess={onSuccess} />
                    </div>
                </div>
            </PageContent>
        </>
    );
}